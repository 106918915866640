import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue} from "bootstrap-vue";
import router from "./router";
import store from "./store";
import axios from 'axios'
import VueAxios from "vue-axios";
import '@/assets/styles/globals/_boostrap.scss';
import Notifications from 'vue-notification'
import {setupAxiosInterceptors} from './store/interceptors';
import localforage from "localforage";
Vue.config.productionTip = false
import defaultMixin from "./mixins/default"
import i18n from "./helpers/i18n";
import {config} from "./utils/config";

localforage.config({name: 'mbg'});

Vue.use(BootstrapVue);

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
});

setupAxiosInterceptors(store, router, axiosInstance);

Vue.use(Notifications)

Vue.use(VueAxios, axiosInstance);

Vue.mixin(defaultMixin);

new Vue({
  render: h => h(App),
  i18n,
  store: store,
  router: router,
  created(){
    store.dispatch('init');
  }
}).$mount('#app')
