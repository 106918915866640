
<template>

  <div class="tab-title row align-items-center position-relative">

    <div v-if="icon" :class="centered ? 'col-12 text-center pb-md-2': 'd-flex align-content-center col-1 mr-2'">
      <span v-if="icon" :class="'icon ' + icon" class="tab-title-icon"/>
    </div>
    <div :class="centered ? 'col-12' : (icon ? 'col-9' : 'col-12')">
      <div class="tab-title-content" :class="centered ? 'text-center' : ''">
        <h5 class="tab-title-heading mb-0">{{title}}</h5>
        <p class="tab-title-subtitle mb-0">{{subtitle}}</p>
      </div>
    </div>


    <div v-if="isLocked" style="position:absolute;top:-10px;right:2px;">
      <IconLock/>
    </div>




  </div>

</template>

<script>
import IconLock from "../icons/IconLock.vue";

export default{
  components: {IconLock},

  props:{
    title: String,
    subtitle: String,
    icon: String,
    centered: {
      type: Boolean,
      default: false
    },
    isLocked:{
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.tab-title{
  padding:0.2rem;
  position:relative;

  &.properties-tab-title{
    width:100px;
    @media(min-width:1240px){
      width:125px;
    }
    @media(min-width:1600px){
      width:150px;
    }
  }

  .tab-title-icon{
    font-size:15px;
  }
  .tab-title-content{
    text-align:left;
  }
  .tab-title-heading{
    font-size:10px;
  }
  .tab-title-subtitle{
    font-weight:300;
    font-size:0.7rem;
    opacity:0.6;
  }
}
</style>