<template>
  <div class="pt-5">
    <span :class="icon" style="font-size:75px;" />
    <h1 class="pt-4">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
  }
}
</script>