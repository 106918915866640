<template>
  <div class="related-link-toggle casual-border-radius-small"
    :class="isReverseBackground ? 'reverse-bg': ''">
    <div class="related-link-toggle-header cursor-pointer px-2 px-md-3 d-flex align-items-center"
         @click="handleToggle"
         @mouseover="highlight"
         @mouseleave="clearAllHighlights"
    >
      <div class="mb-0 d-flex align-items-center gap-5">
        <span v-if="icon" :class="`${icon} ${renderProps.customIconClasses || ''}`"/>
        <span v-if="type === 'planet'" :class="`icon-${icons[+renderProps.id]} ${renderProps.customIconClasses || ''}`"/>
        <div v-if="type === 'gate'" v-html="getIchingIcon(renderProps.id)" class="d-flex align-items-center mb-1"/>
        <div v-html="label"></div>
        <p class="my-0 font-weight-normal ml-2">{{ text }}</p>
      </div>

      <span class="ease-in-out icon-arrow-down duration-300 ml-auto "
            :class="isNested ? 'link-style icon-view' : ''"
      :style="isToggled ? 'transform:rotate(180deg);':
      (isNested ? 'transform:rotate(-90deg)' : '')"/>
    </div>
    <div v-if="isToggled" class="related-link-toggle-content">
      <component :key="isToggled"
                 v-if="isToggled"
                 :is="getComponent"
                 v-bind="{...renderProps, parentReverseBackground: isReverseBackground, isRelatedToggle: true, showRelatedLinks: true, depth: (depth ?? 1) + 1}"
                 class="mt-0"
      />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {getIchingIcon} from "@/utils/iching";
import icons from "@/helpers/rave/constants/planets";
import {mapState} from "vuex";
import util from "@/mixins/util";
import HighlightingMixin from "@/mixins/HighlightingMixin";
import channels from "@/helpers/rave/constants/channels";
import centerConstants from "@/helpers/rave/constants/centers";

export default {
  name: "related-link-toggle",
  mixins: [util, HighlightingMixin],
  props:{
    type: String,
    icon: String,
    label: String,
    text: String,
    startOpen: Boolean,
    renderProps: Object,
    canToggle: {
      type: Boolean,
      default: true
    },
    isReverseBackground: {
      type: Boolean,
      default: false
    },
    isNested: Boolean,
    depth: Number,
  },

  computed: {
    ...mapState({
      tabs: state => state.ui.tabsMapping
    }),
    icons(){
      return icons.icons
    },
    getComponent(){
      switch (this.type){
        case 'channel':
          return () => import("../../singles/Channel.vue");
        case 'gate':
          return () => import("../../singles/Gate.vue");
        case 'planet':
          return () => import("../../singles/Planet.vue");
        case 'center':
          return () => import("../../singles/Center.vue")
        default:
          return null;
      }
    }
  },

  data(){
    return {
      isToggled: false,
    }
  },

  mounted(){
    if(this.startOpen && !this.isNested){
      this.handleToggle()
    }
  },

  methods: {
    getIchingIcon,
    handleToggle(){
      if(this.canToggle && !this.isNested) {
        this.isToggled = !this.isToggled;
        return;
      }
      return this.redirectToItem();
    },
    highlight(){
      if (this.type === 'planet') {
        return this.planetHighlight(this.renderProps.id, this.renderProps.activation, [this.renderProps.planet.gate], this.renderProps.chartId)
      }

      const params = {
        type: this.types[this.type],
        id: this.renderProps.id
      };

      if(this.type === 'channel'){
        const gates = channels.find(x => x.id === this.renderProps.id).gates;
        params.extras =  {channelGates: gates}
      }

      if (this.type === 'center') {
        const highlightElement = centerConstants.getById(this.renderProps.id);
        params.id = highlightElement.name.toLowerCase();
      }

     this.highlightElement(params);
    },
    redirectToItem(){
      const item = {
        type: this.type,
        id: this.renderProps.id,
        chartId: this.renderProps.chartId,
        activation: this.renderProps.activation
      };

      let urlQuery = '?tab='+ this.tabs[item.type] + '&selected='+ item.id;

      if(Object.hasOwn(item, 'chartId')
          && item.chartId !== undefined
      ) urlQuery += '&chart=' + item.chartId;
      if(Object.hasOwn(item, 'activation')
          && item.activation !== undefined
      ) urlQuery += '&activation=' + item.activation;
      this.fixUrlQuery(urlQuery);
      // add the URL params so that the properties panel show the correct content

      this.$nextTick(()=>{
        this.$store.dispatch('ui/increasePropertiesKey'); // increase key to update window
        this.$store.commit('ui/SET_SHOW_PROPERTIES', true); // show panel in case it's not showing
      });
    }
  }
}
</script>
<style lang="scss">
.theme--light{
  .related-link-toggle{
    background:#f9f9f9;
    &.reverse-bg{
      background:white;
      box-shadow: 0 10px 25px rgba(24, 28, 36, 0.02);
    }
  }
}
.theme--dark{
  .related-link-toggle{
    background: $bg-dark;
    &.reverse-bg{
      background: $bg-contrast-dark-low-opacity;
      box-shadow: 0 10px 25px rgba(24, 28, 36, 0.07);
    }
  }
}
.related-link-toggle-header{
  padding-top: 10px;
  padding-bottom: 10px;
  &:hover{
    .link-style {
      padding-top:5px;
    }
  }
}
.related-link-toggle-content{
  padding-left: 15px;
  padding-right: 15px;
}
</style>