<template>
  <div >
    <side-extended-panel id="import-charts"
                         :close-btn-text="$t('owl.close')"
                         :visible="show"
                         size="x-small"
                         extra-container-classes="p-0"
                         @close="close">
      <template #header>
        <new-header :title="$t('imports.title')" icon="icon-download"/>
      </template>
      <div v-if="!isImporting && !importId" class="px-4 text-center">
        <p>
          {{ $t('imports.description') }}
        </p>

        <label for="dropzone-file" class="dropzone d-flex flex-column align-items-center justify-content-center w-100 border
        border-dashed rounded-lg cursor-pointer bg-transparent" style="height:175px;border-width:1.5px!important;border-style: dashed!important;"
               :class="validFile ? 'border-success selected' : 'border-dark'">
          <div class="d-flex flex-column align-items-center justify-content-center pt-5 pb-4"
               @drop="e => fileSelected(e, true)"
               @dragover.prevent=""
          >
            <svg class="w-8 h-8 mb-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16"
                 style="height:30px;width:30px;">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
            </svg>
            <div v-if="!selectedFile">
              <p class="mb-2 text-lg font-500"><span class="font-semibold">Click to upload</span> or drag and drop</p>
              <p class="font-weight-normal">.mmi, .s3db accepted</p>
            </div>
            <div v-else>
              <label class="font-weight-normal">Selected file</label>
              <p>
                {{selectedFile?.name}}
              </p>
            </div>

          </div>
          <input id="dropzone-file" type="file" style="display:none;" @change="fileSelected" />
        </label>

        <div class="py-4">
          <p class="text-normal mbg-text-muted text-left">
            {{$t('imports.tags')}}
          </p>
          <multiselect v-model="tags"
                       id="import-tags-selector"
                       :tag-placeholder="$t('tags.addPlaceholder')"
                       placeholder="Start typing a tag..."
                       :options="availableTags.map(x => x.text)"
                       :multiple="true" :taggable="true"
                       @tag="addTag"
                       @remove="removeTag"
          ></multiselect>
        </div>
        <div v-if="isDBFile" class="text-left">
          <b-form-checkbox v-model="useFoldersAsTags"
                           name="folder-as-tags" class="cursor-pointer" switch>
            <div>
              <span class="cursor-pointer font-500" style="font-weight:500!important">{{$t('imports.convertFolders.title')}}</span>
              <p class="text-sm cursor-pointer">
                {{$t('imports.convertFolders.paragraph')}}
              </p>
            </div>

          </b-form-checkbox>
        </div>

        <b-button variant="primary" class="mt-4" block :disabled="!validFile || importStarting" @click="importFile">Import</b-button>

      </div>

      <div v-else class="px-4 text-center">
        <div v-if="completedImport">
          <p>
            {{ $t('imports.completed') }}
          </p>

          <b-table striped :items="Object.entries(results).map(x => ({
          category: x[0],
          total: x[1]
        }))">
            <template #head(category)>
              {{ $t('imports.category') }}
            </template>

            <template #head(total)>
              {{ $t('imports.total') }}
            </template>

            <template #cell(category)="data">
              <span>{{ $t('imports.statuses.'+data.item.category) }}</span>
            </template>
          </b-table>

          <div class="text-center mt-4">
            <b-button variant="primary" class=""  @click="startOver">{{ $t('imports.startOver') }}</b-button>
          </div>

        </div>
        <div v-else>
          <p>
            {{ $t('imports.importing') }}
          </p>
          <b-spinner variant="primary"/>
        </div>
      </div>

    </side-extended-panel>


    <b-card v-if="importId && !show" class="import-tooltip" style="position:fixed;bottom:10px;right:10px;width:320px; z-index:9999;">

      <div class="d-flex align-items-center gap-10" >
        <b-spinner v-if="!completedImport" variant="primary" small class="flex-shrink-0"/>
        <span v-else class="icon-check text-primary text-lg" />
        <p class="text-normal mb-0">

          <span v-if="!completedImport">{{$t('imports.tooltip.inProgress')}}</span>
          <span v-else> {{$t('imports.tooltip.completed') }} <br/><span class="text-primary cursor-pointer"
                                                                   @click="$store.commit('ui/SET_SHOW_IMPORT_CHARTS', true)"
          >{{$t('imports.tooltip.completedCta')}}</span></span>

        </p>
      </div>


    </b-card>
  </div>

</template>
<style lang="scss">
.dropzone{
  &:not(.selected){
    &:hover{
      border-color:$primary!important;
    }
  }
}
.import-tooltip{
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
  width:320px;
  max-width:100%;
  @media(max-width:768px) {
    margin: 0 auto;
    left:0;
    right:0 ;
  }
}
.multiselect__content{
  padding-left:0!important;
}
#import-tags-selector{
  &.multiselect__input{
    padding:0!important;
    outline:none!important;
    border:none!important;
    //opacity:0!important;
    height:0!important;
    //display:none!important;
    &::placeholder{
      font-size:0.8rem;
    }
  }

}
</style>
<script>
import SideExtendedPanel from "@/components/structure/SideExtendedPanel.vue";
import {mapState} from "vuex";
import NewHeader from "@/components/structure/chart-sidebars/partials/NewHeader.vue";
import ChartCreationMixin from "@/mixins/ChartCreationMixin";
import Multiselect from "vue-multiselect";

export default {
  components: {Multiselect, NewHeader, SideExtendedPanel},
  mixins:[ChartCreationMixin],
  computed: {
    ...mapState({
      show: state => state.ui.showImportCharts,
      importId: state => state.charts.importId,
      chartsToken: state => state.account.chartsToken,
      availableTags: state => state.account.tags
    }),

    isDBFile() {
      return this.selectedFile?.name?.toLowerCase().endsWith('.s3db');
    },

    validFile() {
      return this.selectedFile &&
          this.selectedFile?.name?.toLowerCase().endsWith('.mmi') ||
          this.selectedFile?.name?.toLowerCase().endsWith('.s3db');
    },

    completedImport() {
      return Object.keys(this.results).length > 0;
    }
  },
  watch: {
    chartsToken() {
      this.getImportStatus();
    }
  },

  mounted() {
    if (!this.chartsToken) return;
    this.getImportStatus();
  },
  data(){
    return {
      tags: [],
      selectedFile: null,
      useFoldersAsTags: false,
      customTags: [],
      isImporting: false,
      results: {},
      importStarting: false,
    }
  },

  methods:{
    fileSelected (e, isDrop = false) {
      e.stopPropagation()
      e.preventDefault();
      if (isDrop) {
        if (e.dataTransfer.items) {
          for (let i = 0; i < e.dataTransfer.items.length; i++) {
            if (e.dataTransfer.items[i].kind === 'file') {
              this.selectedFile = e.dataTransfer.items[i].getAsFile();
              break;
            }
          }
        }
      } else {
        this.selectedFile = e.target.files[0];
      }
      this.useFoldersAsTags = this.isDBFile; // if is dbImport, enable this.
    },

    close(){
      this.$store.commit('ui/SET_SHOW_IMPORT_CHARTS', false);

      if (this.completedImport) {
        this.startOver();
      }
    },

    importFile() {
      if (this.importStarting) return;
      if (!this.validFile) return;
      this.importStarting = true;
      const formData = new FormData()
      formData.append('file', this.selectedFile);
      formData.append('tags', this.tags);
      formData.append('useFoldersAsTags', this.useFoldersAsTags);
      this.axios({
        method: "POST",
        url: this.endpoints.IMPORT_CHART(this.chartsUrl),
        data: formData,
        headers: { "Content-Type": "multipart/form-data" }
      }).then(res => {
        const result = res.data;
        this.importId = result.id;
        this.$store.commit('charts/SET_IMPORT_ID', result.id);
        this.isImporting = true;
        this.getImportStatus();
        this.close();
      }).catch(() => {
        this.$notify({type: 'error', title: 'Something went wrong',
          text: 'Something went wrong with upload this file. Please selected a different file.',
          position: 'bottom center'});
      }).finally(()=>{
        this.importStarting = false;
      });
    },

    getImportStatus() {
      if (!this.importId) return;

      this.axios.get(this.endpoints.IMPORT_STATUS(this.chartsUrl, this.importId)).then(res => {
        if (res.data.state === 'COMPLETED') {
          this.results = res.data.counts;
          this.$store.commit('charts/SET_IMPORT_COMPLETED', true);
          this.$store.dispatch('account/getUserTags');
        } else {
          setTimeout(() => {
            this.getImportStatus()
          }, 1000);
        }
      })
    },

    startOver(){
      this.tags = [];
      this.selectedFile = null;
      this.$store.commit('charts/SET_IMPORT_COMPLETED', false);
      this.$store.commit('charts/SET_IMPORT_ID', null);
      this.isImporting = false;
      this.results = {};
    },

    addTag(val) {
      if (typeof val === 'string') {
        this.tags.push(val);
      } else {
        this.tags.push(val.text);
      }
    },
    removeTag(val){
      this.tags = this.tags.filter(x => x !== (val?.text ?? val))
    }
  }


}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>