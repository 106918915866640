<template>

  <div id="gates" class="unselectable" :class="showAsCard ? 'card': ''">

    <div :class="showAsCard ? 'card-body': ''">
      <div class="d-flex align-items-center">
        <b-button v-if="isGateSelected" size="xs" variant="primary" class="m-0 mr-3 " @click="goBack">
          <span class="icon-arrow-left"></span>
        </b-button>

        <mbg-text-input v-model="search" :show-label="false"
                        :title="$t('header.menu.search') + ' ' + $t('hd.gates.name.many')"  icon="icon-search" class="mb-0 w-100"/>

      </div>
    <!--  Renders gate itself  -->

      <div v-if="isGateSelected && !searching">
        <Gate :key="contentKey" :prev="getPrevGate" :next="getNextGate" :gate-id="selectedGate"
               :show-related-links="showAsCard"
               @changeGate="gate => selectGate(gate)"/>
        <unlock-content-prompt v-if="!isPropertiesUnlocked && !disableUrlNavigation" :chart-id="chart.id" :category="getUnlockCategory"/>
      </div>

    <!--   Renders list of gates! -->
      <div v-else class="pb-3">
        <div v-for="gate in getGates" :key="gate.gate" @click="selectGate(gate.gate);"
             @mouseover="highlightElement({type: types.gate, id: gate.gate})"
             @mouseleave="clearAllHighlights" class="highlight-trigger">
          <b-card class="my-2 cursor-pointer border-primary">

            <div class="d-flex align-items-center">

              <div>
                <div class="num-box float-left text-center cursor-pointer" :class="`${filterByPlanetaryType || ''}`">
                  {{gate.gate}}
                </div>
              </div>
              <div>
                <label class="font-weight-light">{{gate.name }}</label>
                <h6>{{gate.keynote}}</h6>
              </div>
              <div class="ml-auto">
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 13L7 7L0.999999 1" :stroke="darkMode ? '#fff' : '#191c23'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>

          </b-card>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapState} from "vuex";
import MbgTextInput from "../../elements/MbgTextInput";
import Gate from "./singles/Gate";
import util from "../../../mixins/util";
import highlightingMixin from "../../../mixins/HighlightingMixin";
import UnlockContentPrompt from "../../billing/UnlockContentPrompt.vue";
import ChartUtil from "../../../mixins/ChartUtil";

export default{
  mixins: [util, highlightingMixin, ChartUtil],
  components:{UnlockContentPrompt, Gate, MbgTextInput},

  props:{
    sort: {
      type: String,
      default: 'any'
    },
    showAsCard:{
      type: Boolean,
      default: true
    },
    filterByPlanetaryType: {
      type: String,
      default: null
    },
    showPlanets: {
      type: Boolean,
      default: false
    },
    // This is "true" when we're on "analysis" but not on properties.
    // This disables URL navigation, and also hides the double "unlock" button.
    disableUrlNavigation: {
      type: Boolean,
      default: false
    }
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),

    isGateSelected(){
      return this.selectedGate !== null && this.selectedGate >= 0;
    },

    getGates(){

      let gates = structuredClone(this.gates);

      if(this.filterByPlanetaryType){
        if(this.filterByPlanetaryType === 'design'){
          gates = this.gates.filter(x => x.design !== undefined);
          gates.sort((a, b) => a.design - b.design)
        }
        if(this.filterByPlanetaryType === 'personality'){
          gates = this.gates.filter(x => x.personality !== undefined);
          gates.sort((a, b) => a.personality - b.personality)
        }
      }
      if(this.search) return gates.filter(x =>
          x.gate.toString().includes(this.search) ||
          x.name.toString().toLowerCase().includes(this.search.toLowerCase()) ||
          x.keynote.toString().toLowerCase().includes(this.search.toLowerCase())
      )

      return gates;
    },

    getPrevGate(){
      return this.getGates[this.getGates.findIndex(x => x.gate === this.selectedGate)-1];
    },

    getNextGate(){
      return this.getGates[this.getGates.findIndex(x => x.gate === this.selectedGate)+1];
    }
  },
  watch:{
    search(val){
      if(val && val !== '') this.searching = true;
      else this.searching = false;
    },
    selectedGate(val){
      this.$emit('hasSelectedItem', !isNaN(parseInt(val)));
      if(this.disableUrlNavigation) return;
      if(val) this.urlQuery.set('selected', val);
      else this.urlQuery.delete('selected');
      this.fixUrlQuery('?' + this.urlQuery.toString());
    }
  },


  data(){
    return{
      selectedGate: null,
      search: null,
      searching: false,
      gates: [],
      urlQuery: null,
      contentKey: 0
    }
  },

  created(){
    this.urlQuery = this.getQuery();
    this.fixActiveGate();
    this.setupGateContent();

  },

  methods:{

    setupGateContent(){
      let gates = [];
      let gateActivations = {}

      for(let planet of this.chart.chart.planets){
        if(!gateActivations[planet.gate]) gateActivations[planet.gate] = {
          design: undefined,
          personality: undefined
        }
        if(planet.activation === 1){
          let existing = gateActivations[planet.gate].personality
          if(existing !== undefined && planet.id > existing) continue;
          gateActivations[planet.gate].personality = planet.id;
        }
        if(planet.activation === 0){
          let existing = gateActivations[planet.gate].design
          if(existing !== undefined && planet.id > existing) continue;
          gateActivations[planet.gate].design = planet.id;
        }
      }

      for(let gate of this.chart.chart.gates){
        gates.push({
          gate: gate.gate,
          name: this.$t('hd.gates.'+gate.gate + '.name'),
          keynote: this.$t('hd.gates.'+gate.gate + '.titleKeynote'),
          mode: gate.mode,
          design: gateActivations[gate.gate].design,
          personality: gateActivations[gate.gate].personality
        })
      }
      this.gates = gates;

    },
    fixActiveGate(){
      this.selectedGate = parseInt(this.urlQuery.get('selected'));
    },
    selectGate(id){
      this.searching = false;
      this.selectedGate = id;
      this.contentKey++;
      this.clearAllHighlights()
    },
    goBack(){
      this.selectedGate = null;
    }
  }
}
</script>
<style lang="scss">
#gates{
  .num-box{
    &.design{
      background-color: $design-bg;
    }
    &.personality{
      background-color: $personality-bg;
    }
  }
}
</style>