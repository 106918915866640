<template>
  <svg id="connection-planet-table-v2" width="100%"
         :viewBox="`0 0 136 ${getPlanets.length * (heightPerPlanet + spacePerPlanet) + (nameHeight*1.5)}`"
         fill="none" xmlns="http://www.w3.org/2000/svg">


      <text fill="currentColor" :transform="`translate(0, 30)`" class="planet-table-title" x="68" y="0"
            font-size="12"
            text-anchor="middle"
      >
          {{ getName }}
      </text>
      <line x1="0" y1="40" x2="136" y2="40" :stroke="orientation === 0 ? '#6a8bb5' : '#83cbb4'" stroke-width="2" />

      <g v-for="(planet,index) in getPlanets" :key="index"
         :id="`planet-${planet.chartId}-${planet.planet}-${planet.chartId}`"
         :transform="`translate(0, ${((index) * (heightPerPlanet + spacePerPlanet)) + (nameHeight * 1.5)})`"
         class="planet-wrapper"
         :class="`g${planet.activations[0].gate} g${planet.activations[1].gate} ${getName ? 'has-name' : ''} ${orientation === 0 ? 'left' : 'right'}`"
         @mouseover="planetHighlight(planet.planet, planet.chartId, [planet.activations[0].gate, planet.activations[1].gate], planet.chartId)"
         @mouseleave="clearAllHighlights"
      >
        <path d="M0 5C0 2.23858 2.23858 0 5 0H126C131.523 0 136 4.47715 136 10V33C136 35.7614 133.761 38 131 38H10C4.47715 38 0 33.5228 0 28V5Z"
              fill="transparent" class="planet-bg"/>

        <!--  left planet (planet[0])      -->
        <text fill="#C25354" xml:space="preserve" style="white-space: pre" font-family="Rubik" font-size="14"
              text-anchor="end"
              x="53"
              y="23.795"
              class="cursor-pointer"
              letter-spacing="0em"
              @click="onElementClicked(planet.planet,1, planet.chartId)"
              @mouseover.stop="() => { clearAllHighlights(); planetHighlight(planet.planet, planet.chartId, [null, planet.activations[0].gate], planet.chartId)}"
              @mouseleave="clearAllHighlights"
        >{{ planet.activations[0].gate }}.<tspan font-size="12">{{planet.activations[0].line}}</tspan></text>

        <g clip-path="url(#clip0_5097_96145)" class="fixing-wrapper" :class="`${planet.activations[0].externalFixing ? 'external-fixing' : ''} ${orientation === 0 ? 'left' : 'right'}`">
          <path v-if="planet.activations[0].isDetriment" class="detriment-path" d="M18.4153 16.75L15 22.074L11.5847 16.75L18.4153 16.75Z" stroke="#C25354" fill="#C25354"/>
          <path v-if="planet.activations[0].isExalted" class="exalted-path" d="M15 16L19.3301 22.75H10.6699L15 16Z" fill="#C25354"/>
          <path v-if="planet.activations[0].isStar" class="star-path" d="M15 15L16.3905 17.9621L19.5 18.4401L17.25 20.7445L17.781 24L15 22.4621L12.219 24L12.75 20.7445L10.5 18.4401L13.6095 17.9621L15 15Z" fill="#C25354"/>
        </g>


        <planet-icons :index="index" x="61" y="24" font-size="13" />
        <!--    right planet (planet[1]) -->
        <text fill="currentColor" x="79" y="23.795" xml:space="preserve" style="white-space: pre" font-family="Rubik" font-size="14"
              text-anchor="start"
              letter-spacing="0em"
              class="cursor-pointer"
              @click="onElementClicked(planet.planet,0, planet.chartId)"
              @mouseover.stop="() => { clearAllHighlights(); planetHighlight(planet.planet, planet.chartId, [planet.activations[1].gate, null], planet.chartId)}"
              @mouseleave="clearAllHighlights"
        >
          {{ planet.activations[1].gate }}.<tspan font-size="12">{{planet.activations[1].line}}</tspan>
        </text>

        <g clip-path="url(#clip1_5097_96145)" class="fixing-wrapper" :class="`${planet.activations[1].externalFixing ? 'external-fixing' : ''} ${orientation === 0 ? 'left' : 'right'}`">
          <path v-if="planet.activations[1].isDetriment" class="detriment-path" d="M124.415 16.75L121 22.074L117.585 16.75L124.415 16.75Z" stroke="currentColor" fill="currentColor"/>
          <path v-if="planet.activations[1].isExalted" class="exalted-path" d="M121 16L125.33 22.75H116.67L121 16Z" fill="currentColor"/>
          <path v-if="planet.activations[1].isStar" class="star-path" d="M121 15L122.39 17.9621L125.5 18.4401L123.25 20.7445L123.781 24L121 22.4621L118.219 24L118.75 20.7445L116.5 18.4401L119.609 17.9621L121 15Z" fill="currentColor"/>
        </g>
        <!--      -->
        <g v-if="planet.activations[1].isExalted" clip-path="url(#clip0_5097_96145)" class="exalted-element">
          <path d="M121 16L125.33 22.75H116.67L121 16Z" fill="currentColor"/>
        </g>
      </g>

      <defs>
        <clipPath id="clip0_5097_96145">
          <rect width="10" height="11" fill="white" transform="translate(10 14)"/>
        </clipPath>
        <clipPath id="clip1_5097_96145">
          <rect width="10" height="11" fill="white" transform="translate(116 14)"/>
        </clipPath>
      </defs>
  </svg>
</template>

<style lang="scss">
.theme--light {
  #connection-planet-table-v2 {
    .planet-wrapper {
      &:hover, &.highlight {
        > .planet-bg {
          fill: #ffffff;
        }
      }
    }
  }
}
.theme--dark {
  #connection-planet-table-v2 {
    .planet-wrapper {
      &:hover, &.highlight {
        > .planet-bg {
          fill: $bg-contrast-dark;
        }
      }
    }
  }
}
#connection-planet-table-v2 {
  svg {
    @media(max-width:767px){
      max-height:75vh!important;
    }
  }

  .planet-table-icon-renderer{
    pointer-events: none;
    font-family: 'MyBodyGraph'!important;
  }

  .fixing-wrapper{
    &.external-fixing {
      &.left{
        .detriment-path {
          stroke: $connection-chart-2;
          fill: $connection-chart-2;
        }
        .exalted-path, .star-path {
          //stroke: $connection-chart-2;
          fill: $connection-chart-2;
        }
      }
      &.right{
        .detriment-path {
          fill: $connection-chart-1;
          stroke: $connection-chart-1;
        }
        .exalted-path, .star-path{
          fill: $connection-chart-1;
        }
      }
    }
  }
}
</style>

<script>

import planetConstants from "@/helpers/rave/constants/planets";
import {mapState} from "vuex";
import util from "@/mixins/util";
import fixings from "@/helpers/rave/constants/fixings";
import HighlightingMixin from "@/mixins/HighlightingMixin";
import ChartUtil from "@/mixins/ChartUtil";
import PlanetIcons from "./partials/PlanetIcons.vue";

export default{
  components: {PlanetIcons},

  mixins: [util, HighlightingMixin, ChartUtil],

  props:{
    orientation: {
      type: Number,
      default: 0
    },
    chart: Object
  },
  computed:{
    ...mapState({
      viewMode: state => state.ui.viewMode,
      viewModes: state => state.ui.viewModes
    }),

    getName(){
      // handle connection chart name
      if(this.isConnectionChart(this.chart)){
        let person = this.chart.meta['person' + (this.orientation === 0 ? '1' : '2')];

        return person ? person.name : null;

      }
      if(this.isTransitChart(this.chart) || this.isCycleChart(this.chart)){
        if(this.orientation === 0 ){
          let person = this.chart.meta.person;
          return person ? person.name : null;
        }else{
          if(this.isCycleChart(this.chart)) return 'Transit'
        }

      }
      return null;
    },

    getPlanets(){

      if(this.viewMode === this.viewModes.cross){
        return this.planets.filter(x => x.chartId === this.orientation &&
            (x.planet === planetConstants.EARTH || x.planet === planetConstants.SUN) )
      }
      return this.planets.filter(x => x.chartId === this.orientation);
    }
  },
  data(){
    return{
      planets: [],
      heightPerPlanet: 38,
      spacePerPlanet: 5,
      nameHeight: 30,
    }
  },
  created(){

    this.setupPlanetContent();
  },
  methods:{
    setupPlanetContent(){
      let planets = [];
      for(let planet of this.chart.chart.planets.filter(x => x.chartId === this.orientation)){

        const conditioned = planet.fixing.conditioned;

        let planetAlreadyInserted = planets.find(x => x.planet === planet.id);
        if(planetAlreadyInserted){
          planetAlreadyInserted.activations[planet.activation] = {
            isExalted: planet.fixing.state === fixings.EXALTED,
            isDetriment: planet.fixing.state === fixings.DETRIMENT,
            isStar: planet.fixing.state === fixings.JUXTAPOSED,
            name: this.$t('hd.planets.' + planet.id + '.name'),
            gate: planet.gate,
            line: planet.line,
            conditioned,
            externalFixing: conditioned
          }
          continue;
        }

        planets.push({
          icon: planetConstants.icons[planet.id],
          planet: planet.id,
          activation: planet.activation,
          chartId: planet.chartId,
          activations: {
            [planet.activation]: {
              isExalted: planet.fixing.state === fixings.EXALTED,
              isDetriment:  planet.fixing.state === fixings.DETRIMENT,
              isStar: planet.fixing.state === fixings.JUXTAPOSED,
              name: this.$t('hd.planets.' + planet.id + '.name'),
              gate: planet.gate,
              line: planet.line,
              conditioned,
              externalFixing: conditioned
            }
          }
        })
      }
      this.planets = planets;
    },
    onElementClicked (planetId, activationId, chartId) {
      let activation = activationId || this.activation === 'design' ? 0 : 1;

      this.fixUrlQuery('?tab=planetary-activations' + '&selected='+planetId + '&activation=' + activation + '&chart=' + chartId);
      // add the URL params so that the properties panel show the correct content

      this.$nextTick(()=>{
        this.$store.dispatch('ui/increasePropertiesKey'); // increase key to update window
        this.$store.commit('ui/SET_SHOW_PROPERTIES', true); // show panel in case it's not showing
      });
    }
  }
}
</script>