<template>
  <b-tabs v-if="loaded" v-model="tab" class="tabs-primary-underlined light-tab-titles" fill lazy @input="$emit('innerTabChanged', tab)">
    <b-tab v-for="(item, index) in tabs.filter(x => !x.hideTab)" :key="item.id" :title="item.title">
      <component v-if="item.shouldRender" :is="item.component" v-bind="item.props" v-on="item.on" />
      <ProgressChecker v-show="!item.hideProgress"
                       :id="id + '.' + (item.id + 1)" :type="type"
                       :has-prev="index === 0 ? id > 0 : true"
                       :has-next="index === tabs.length - 1 ? id < totalOuterTabs - 1 : true"
                       @prevSection="tab > 0 ? tab-- : $emit('prevSection')"
                       @nextSection="tab < tabs.length - 1 ? tab++ : $emit('nextSection')"/>
    </b-tab>
  </b-tabs>
  
</template>
<script>
import DynamicZoneRenderer from "./DynamicZoneRenderer.vue";
import ProgressChecker from "../charts/analysis/progress/ProgressChecker.vue";
import AnalysisQueryMixin from "../../mixins/AnalysisQueryMixin";
import {mapState} from "vuex";

export default {
  components: {ProgressChecker, DynamicZoneRenderer},
  mixins: [AnalysisQueryMixin],
  props:{
    id: Number,
    totalOuterTabs: Number,
    type: String,
    tabs: {
      type: Array,
      required: true
    },
  },
  computed:{
    ...mapState({
      lastInnerTab: state => state.ui.lastInnerTab
    })
  },
  data(){
    return {
      loaded: false,
      tab: 0
    }
  },
  mounted(){
    this.tab = this.lastInnerTab ? this.tabs.length - 1 : 0;
    this.$nextTick(()=>{
      this.loaded = true;
    })

  }
}
</script>