<template>
  <side-extended-panel id="edit-chart"
                       :close-btn-text="$t('owl.close')"
                       :title="`${$t('charts.properties.edit')} ${chart?.meta?.name}`"
                       :visible="show"
                       @close="close">

    <div v-if="chart?.meta" :key="key" class="pb-5 pt-3">

      <!-- Name editing for non-personal chart. Personal chart has its own editor -->
      <div v-if="!isPersonalChart(chart)" class="px-3">
        <mbg-text-input
            v-model="name"
            :placeholder="$t('general.enterName')"
            :title="$t('properties.chartName')"
            input-classes="new-chart-input"
            type="text"
            @blur="updateName"
        />
      </div>

      <Personal v-if="isPersonalChart(chart)" :key="`data-${chart?.id}`" :existing-chart="chart"/>
      <TagEditor :key="`tags-${chart?.id}`" :chart="chart"/>

      <div v-if="!isUserMainChart" class="mt-5 text-right">
        <b-button :disabled="chart?.meta?.is_locked"
                  variant="primary"
                  @click="openDeleteChartModal">
          Delete Chart
        </b-button>
      </div>

      <b-modal ref="delete-chart" ok-title="Delete Chart"
               title="Are you sure you want to delete"
               @ok="deleteChart"
      >
        <p>
          If you delete your chart, there is no way to recover it.
        </p>
        <p>
          <strong>
            Are you sure you want to proceed?
          </strong>
        </p>
      </b-modal>
    </div>
  </side-extended-panel>

</template>

<script>

import SideExtendedPanel from "../SideExtendedPanel.vue";
import {mapState} from "vuex";

import TagEditor from "./partials/TagEditor.vue";
import ChartPageMixin from "../../../mixins/ChartPageMixin";
import Personal from "../../new-chart/Personal.vue";
import chartUtil from "../../../mixins/ChartUtil";
import MbgTextInput from "../../elements/MbgTextInput.vue";

export default {
  components: {MbgTextInput, Personal, SideExtendedPanel, TagEditor},
  mixins: [ChartPageMixin, chartUtil],
  watch: {
    show(val) {
      if (val) this.key++;
    },
    chart(val){
      this.name = val.meta?.name;
    }
  },
  data() {
    return {
      key: 0,
      name: null,
    }
  },

  mounted(){
    this.name = this.chart.meta?.name;
  },
  computed: {
    ...mapState({
      show: state => state.ui.showEditChart,
      chart: state => state.charts.chart,
    }),

    isUserMainChart() {
      return (!this.$store.state.account.user.chart_id) || this.chart.id === this.$store.state.account.user.chart_id
    }
  },
  methods: {
    // Automatically triggered when changing the chart's name.
    updateName() {
      if (this.name === this.chart.meta?.name) return;
      this.updateChartField(this.chart.id, {name: this.name});
    },
    close() {
      this.$store.commit('ui/SET_SHOW_EDIT_CHART', false);
      this.$emit('closed');
    },
    openDeleteChartModal() {
      this.$refs['delete-chart'].show();
    },
    deleteChart() {
      this.deleteDoc(this.chart.id).then(() => {
        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.deleted')});
        this.$emit('deleted', this.chart.id);
        this.$router.push({
          name: 'library'
        });
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    }
  }

}
</script>