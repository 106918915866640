<template>

  <b-card :class="`${isCancelled ? 'opacity-75' :''} ${isToggled ? 'border-primary border': ''}`"
          class="subscription-item mb-2 ease-in-out duration-300 ">


    <span class="float-right mr-4 mt-3 cursor-pointer"  @click="toggleItem">
      {{isToggled ? '-': '+'}}
    </span>

    <div class="row px-3 py-2 cursor-pointer"  @click="toggleItem">
      <div class="col-6 col-md-3">
        <label class="text-xs">{{ $t('billing.status') }}</label>
        <div>
          <b-badge
              :variant=" (isCancelled ? 'danger' : (willCancel ? 'warning' : 'success'))"
          >{{
              $t(`billing.subStatuses.${isActive && willCancel ? 'willCancel' : item.status}`)
            }}</b-badge>
        </div>

      </div>
      <div class="col-6 col-md-3">

        <label class="text-xs">Amount</label>
        <p class="text-sm">
          ${{(item.amount/100).toFixed(2)}}
        </p>

      </div>
      <div class="col-6 col-md-3">
        <label class="text-xs">{{ $t('billing.startedOn') }}</label>
        <p class="text-sm">
          {{getStartDate}}
        </p>

      </div>
      <div v-if="isActive" class="col-6 col-md-3">
        <label class="text-xs">{{ $t('billing.activeUntil') }}</label>
        <p class="text-sm">
          {{getEndDate}}
        </p>

      </div>
      <div class="col-6 col-md-2 align-self-center"></div>
    </div>

    <div v-if="isToggled" class="py-3 px-4 text-left">
        <p v-if="isUnlimited">
          Your subscription was migrated from the former myBodyGraph app and does not expire.
        </p>
        <p v-else-if="isActive">
          Your subscription is <span class="font-500">active</span>  <span v-if="!willCancel">and will renew on <span class="font-500">{{getEndDate}}</span></span>
          <span v-else>and <span class="font-weight-bold">will not renew on the period end</span></span>.
        </p>
        <p v-else-if="isCancelled">
          This subscription was active until {{getEndDate}} and is not currently active.
        </p>
        <div>
          <p class="font-500">
            This subscription includes:
          </p>
          <ul >
            <li v-for="prod in item.products" :key="prod.key" class="mb-2">
              <p class="font-weight-bold mb-1">
                {{prod.name}}
              </p>
              <span class="mbg-text-muted">
              {{prod.short_description}}
            </span>
            </li>
          </ul>
        </div>

      <b-modal ref="cancel-subscription" title="Are you sure you want to cancel this subscription"
               @ok="cancelSubscription" ok-title="Cancel subscription">
        <p>If you cancel your subscription, you will still maintain access until the period end.</p>
        <p><strong>Are you sure you want to proceed?</strong></p>
      </b-modal>

      <div class="grid grid-cols-md-2 align-items-center mt-4 gap-5">
        <div>
          <div v-if="isActive && !isUnlimited" class="border-t border-primary">
            <b-button v-if="!willCancel" variant="primary-link" :disabled="editing" @click="showCancelModal">Cancel Subscription</b-button>
            <b-button v-else variant="primary-link" :disabled="editing" @click="restartSubscription">Enable renewal</b-button>
          </div>
        </div>

        <div class="text-right">
          <label>Invoices </label>
          <a v-for="inv in item.invoices" :key="inv.id"
             :href="invoiceUrl(inv)" class="font-weight-bold d-block text-right" target="_blank">Download Invoice #{{inv.id}}</a>

        </div>
      </div>

     </div>
  </b-card>
</template>

<style lang="scss" scoped>
.purchase-item{
  border:1px none;
  label{
    margin-bottom:4px;
  }
  .card-body{
    padding:0!important;
    p{
      margin-bottom:0!important;
    }
  }
}
</style>
<script>
import moment from "moment";
import {config} from "../../utils/config";

export default{
  props:{
    item: Object
  },
  computed:{
    getStartDate(){
      return moment.unix(this.item.start_date).format(this.$store.state.account.generalOptions.dateFormatDisplay)
    },

    isUnlimited(){
      return this.item.provider_order_id.startsWith('DEPRECATED_')
    },
    getEndDate(){

      if(this.isUnlimited) return this.$t('billing.unlimited');
      return moment.unix(this.item.end_date).format(this.$store.state.account.generalOptions.dateFormatDisplay)
    },
    isCancelled(){
      return this.item.status === 2
    },
    isActive(){
      return this.item.status === 1
    },
    willCancel(){
      return !!this.item.cancel_at_period_end;
    }
  },
  data(){
    return{
      editing: false,
      isToggled: false,
      chartInfo: [],
    }
  },
  methods:{
    invoiceUrl(item) {
      return item.id ? `${config.apiUrl}/billing/invoice?token=${item.token}` : null
    },
    toggleItem(){
      this.isToggled = !this.isToggled;
    },
    cancelSubscription(){
      this.editing = true;
      this.axios.post(`/billing/${this.item.provider_order_id}/cancel`).then( () =>{
        // prevent fetching
        // eslint-disable-next-line vue/no-mutating-props
        this.item.cancel_at_period_end = true;
      }).catch( () =>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }).finally(() => {
        this.editing = false;
      })

    },
    showCancelModal(){
      this.$refs['cancel-subscription'].show()
    },
    restartSubscription(){
      this.editing = true;
      this.axios.post(`/billing/${this.item.provider_order_id}/restart`).then( () =>{
        // prevent fetching
        // eslint-disable-next-line vue/no-mutating-props
        this.item.cancel_at_period_end = false;
      }).catch( () =>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }).finally( () => {
        this.editing = false;
      })

    }
  }
}
</script>