<template>
  <header id="header" ref="header-cmp" :class="searching ? 'searching' : ''" class="py-2">
    <div class="header-bar-container">
      <div class="row align-items-center px-4">
        <div :class="searching ? 'col-12' :''" class="col-8  col-lg-6 d-flex align-items-center">

          <router-link :class="searching ?'desktop-version': ''" :to="{name:'home'}">
            <img :src="loadLocalIcon(`logo-${getDarkModeText}.svg`)" class="logo-img"/>
          </router-link>

          <div :class="searching ? 'desktop-version': ''" class="mobile-version header-title font-weight-bold">
            <b-badge v-if="title.showCelebrity" class="bg-purple text-xl">
              <span class="icon-star"/>
              {{$t('library.celebrity.one')}}
            </b-badge>
            <div>
              {{ title.text }}
            </div>
          </div>
          <div :class="searching ? 'desktop-version': ''" class="mobile-version header-title font-weight-bold">

          </div>
          <div :class="searching ? 'd-block w-100' : 'desktop-version'"
               class="desktop-menu">
            <div :class="searching ? 'w-100 h-100' :''"
                 :style="`${searching ? 'padding-top:0!important;padding-bottom:0!important;': ''}`"
                 class="desktop-menu-item" @click.self="toggleSearch">
              <span :class="searching ? 'icon-close close-search-header' :'icon-search'" class="" @click="toggleSearch"/>
              <span v-if="!searching" class="text desktop-version" @click="toggleSearch">
                   {{ $t('header.menu.search') }}
              </span>
              <b-form-input v-if="searching" ref="search-bar" v-model="searchQuery"
                            :placeholder="$t('header.menu.searchPlaceholder')"
                            autofocus class="m-0 p-0 header-invisible-input h-100"
                            debounce="350"></b-form-input>

            </div>
            <div v-if="!searching" class="d-inline-flex desktop-version">
              <div :class="$route.name === 'library' ? 'current' : ''" class="desktop-menu-item"
                   @click="redirectTo('library')">
                <span class="icon-summary"/>
                <span class="text">
                     {{ $t('header.menu.library') }}
              </span>
              </div>

              <div class="desktop-menu-item  desktop-version colored" @click="redirectTo('new-chart')">
                <span class="icon-plus"/>
                <span class="text">
                     {{ $t('header.menu.new') }}
              </span>
              </div>
            </div>

          </div>


        </div>
        <div :class="searching ?'xl-version': ''"
             class="col-4 col-lg-6 text-right d-flex align-items-center justify-content-end desktop-version">

          <div class="desktop-menu desktop-version">
            <div class="desktop-menu-item right" @click="openHelpWidget">
              <span class="icon-help"/>
            </div>
          </div>

          <div class="desktop-menu desktop-version">
            <router-link :to="'/charts/transit/just-now/' + this.user.chart_id" class="desktop-menu-item right router-link-passive ">
              <span class="icon-person-cross"/>
            </router-link>
          </div>

          <div class="desktop-menu desktop-version">
            <div class="desktop-menu-item right" @click="switchDarkMode">
              <span :class="darkMode ? 'icon-dark-mode' : 'icon-half-moon'"/>
            </div>
          </div>

          <div class="desktop-menu desktop-version">
            <div class="desktop-menu-item right position-relative" @click="openDictionary">
              <span class="icon-knowledge"/>
              <div v-if="notifications.data.length > 0 && notifications.data[0].id !== settings.last_notification_read"
                   class="position-absolute bg-primary  rounded-circle"
                   style="top:0; right:5px; height:5px; width: 5px"
              />
            </div>
          </div>
          <div :class="$route.name === 'checkout' ? 'opacity-50' : ''"
               class="desktop-menu desktop-version position-relative">
            <div class="desktop-menu-item right" @click="$route.name === 'checkout'?  '' : redirectTo('checkout')">
              <span class="icon-cart"/>
            </div>
            <b-badge v-if="$store.state.checkout.single_cart_items.length > 0" class="position-absolute" pill size="sm"
                     style="border-radius:100%;top:0;left:0;" variant="primary">
              {{ $store.state.checkout.single_cart_items.length }}
            </b-badge>
          </div>

          <p class="user-name mb-0  ml-0 mr-3 cursor-pointer" @click="$router.push('/charts/' + user.chart_id)">
            {{ formatUserName }}
          </p>


          <div class="position-relative">
            <img :src="loadLocalIcon(`avatar.svg`)" class="avatar-btn" @click="showAccountPopup = true"/>
            <header-popup v-if="showAccountPopup" :user="user" @closePopup="hidePopup"
                          @closed="showAccountPopup = false" @logout="logout"/>
          </div>


        </div>

        <div :class="searching ?'desktop-version': ''" class="col-4 text-right mobile-version">


          <div class="menu-button-wrapper d-flex align-items-center justify-content-end mb-0">
            <b-button class="mr-2" size="xs" variant="link" @click="toggleSearch"><span class="icon-search"/></b-button>
            <b-button class="reverse-radius" size="xs" variant="primary" @click="redirectTo('new-chart')"><span
                class="icon-plus"/></b-button>
            <div class="icon-left ml-3" @click="fullScreenMenu = !fullScreenMenu"/>
          </div>
        </div>
      </div>
    </div>

    <search-results v-if="!isLibrary && searching"
                    :is-visible="searching"
                    :search-text="searchQuery"
                    @closeSearch="searching = false"/>

    <mobile-side-menu :full-screen-menu="fullScreenMenu" @closeMenu="fullScreenMenu = false"
                      :open-zendesk="openHelpWidget"
                      @logout="logout"/>
  </header>
</template>

<script>

import {mapState} from "vuex";
import HeaderPopup from "./HeaderPopup";

import MobileSideMenu from "./MobileSideMenu";
import SearchResults from "./SearchResults";
import RecentChartsListMixin from "../../mixins/RecentChartsListMixin";
import {openZendesk} from "../../helpers/zendesk";


export default {
  components: {HeaderPopup, MobileSideMenu, SearchResults},

  mixins: [RecentChartsListMixin],

  computed: {
    ...mapState({
      user: state => state.account.user,
      isLoggedIn: state => state.account.isLoggedIn,
      title: state => state.ui.title,
      notifications: state => state.account.notifications,
      settings: state => state.account.settings
    }),
    formatUserName() {
      return this.user.fullName ? this.user.fullName.split(" ")[0] : "";
    },
    isLibrary() {
      return this.$route.name === 'library'
    }
  },
  watch: {
    searchQuery(val) {
      this.$store.commit('ui/SET_SEARCH', {
        keyword: val
      });
    },
    searching(scroll) {
      this.setBodyScrollBehavior(!scroll || this.$route.name === 'library');
    },

    showAccountPopup(scroll) {
      this.setBodyScrollBehavior(!scroll);
    }
  },
  mounted() {
    this.setBodyScrollBehavior(true);
  },
  methods: {
    viewJustNowConnection() {
      this.$router.push('/charts/transit/just-now/' + this.user.chart_id);
    },
    logout() {
      this.$store.dispatch("account/logout").then(() => {
        this.$router.push({name: 'login'})
      });
    },
    toggleSearch() {
      if (this.searching) {
        this.$store.commit('ui/SET_SEARCH', {active: false});
        return this.searching = false;

      }
      this.searching = true;
      this.$store.commit('ui/SET_SEARCH', {active: true});

      this.$nextTick(()=>{
        if(this.$refs['search-bar']){
          this.$refs['search-bar'].$el.focus();
        }
      })

    },
    hidePopup() {
      if (!this.showAccountPopup) return;
      if (this.showAccountPopup) this.showAccountPopup = false;
    },

    openDictionary() {
      this.$store.commit('ui/SET_SHOW_OWL', true);
    },

    openHelpWidget() {
      openZendesk();
    }

  },

  data() {
    return {
      fullScreenMenu: false,
      showAccountPopup: false,
      searching: !!this.$route.query.search,
      searchQuery: this.$route.query.search || ''
    }
  },


}
</script>
<style lang="scss">
.theme--light {
  #header {
    background: #fff;

    &.searching {
      //background: $bg-light;
    }
  }
}

.theme--dark {
  #header {
    background: linear-gradient(0deg, #434343, #434343), linear-gradient(0deg, #2F2F2F, #2F2F2F), #191C23;

    &.searching {
      background: $bg-contrast-dark;
    }
  }
}

#header {
  position: relative;
  overflow-x: hidden;
  z-index: 999;

  .user-name {
    font-weight: 500;
  }

  .header-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .desktop-version {
    @media(max-width: 767px) {
      display: none !important;
    }
  }

  .xl-version {
    @media(max-width: 1240px) {
      display: none !important;
    }
  }

  box-shadow: 0 10px 25px rgba(24, 28, 36, 0.05);
  border-radius: 0 0 30px 30px;

  &.searching {
    border-radius: 0;
    box-shadow: unset;
    z-index: 1001;
  }

  .logo {
    z-index: 99999;

  }

  .logo-img {
    min-width: 25px;
    max-width: 50px;
  }

  a {
    font-size: 1.2em;
    font-weight: 400;
    margin-right: 1em;
    text-decoration: none;
  }


  .header-bar-container {
    .col-12 {
      margin-top: 0;
    }
  }

  .desktop-menu {
    display: flex;
    margin-top: 5px

  }

  .desktop-menu-item {
    align-items: center;
    display: inline-flex;
    font-size: 12px;
    cursor: pointer;
    padding: 10px 15px !important;
    margin: 0 .5rem;

    [class^='icon-'] {
      font-size: 15px;
      padding-right: .6rem;
      margin-top: 1.5px;
    }

    .text {
      display: inline;

    }

    &.colored {
      [class^='icon-'] {
        color: #fff;
      }

      background: linear-gradient(0deg, #C25354, #C25354), linear-gradient(0deg, #D14B49, #D14B49), linear-gradient(0deg, #D04948, #D04948), #AF0201;
      border-radius: 15px 10px;
      color: #fff;
    }

    &.current {
      background: $active-menu-item;
      border-radius: 15px 10px;
    }

    &.right {
      padding: 0 !important;
      margin: 0 .5rem;

      [class^='icon-'] {
        font-size: 20px;
      }
    }
  }

  .avatar-btn {
    cursor: pointer;
    max-height: 50px;
    min-width: 40px;
  }
}

.menu-button-wrapper {
  z-index: 9999;
  font-size: 1.5rem;
}

.close-menu {
  font-size: 1.5rem;
}

</style>