<template>

  <div id="new-chart" class="default-page-paddings">


    <div class="row align-items-start">
      <div class="col-12">
        <h2>{{ $t('pages.newChart.title') }}</h2>
      </div>

      <div class="col-12 mt-3 position-relative">

        <b-tabs v-model="currentHash" class="vertical-tabs" @activate-tab="tabSwitch" vertical fill lazy>
          <b-tab v-for="tab in tabs.filter(x => !!x.show)" :key="tab.title" @click="tab.clickAction ? tab.clickAction() : ''">
            <template #title><TabTitle :title="tab.name" :icon="tab.icon"/></template>

            <div class="row">
              <div class="col-12 creation-card-wrap col-md-10 col-xl-8">
                <b-overlay :show="loading" rounded="sm">
                  <b-card>
                    <b-card-body>
                      <component v-if="tab.component" :is="tab.component" @updating="changeUpdatingMode"/>
                    </b-card-body>

                  </b-card>
                </b-overlay>
              </div>
            </div>
          </b-tab>

        </b-tabs>

      </div>
      <bodygraph
          id="bodygraph"
          ref="bodyGraph"
          :chart="chart || {}"
          :class="Object.keys($store.state.charts.newChartAnimationClasses).join(' ')"
          dom-ref-id="bodygraph"
          :interactive="false"
      />
    </div>
  </div>

</template>

<style lang="scss">

#new-chart{
  .creation-card-wrap{
    padding-bottom:100px; // add the distance that autocomplete fields might take so we are not overflowing under the screen.
    .card{
      min-height:600px;
    }
  }

  #bodygraph{
    position:absolute;
    right:150px;
    top:150px;
    height:auto;
    max-width:450px;
    @media(min-width:1600px){
      max-width:500px;

      right:200px;
    }
    @media(max-width:1240px){
      display:none;
      //left: 0;
      //right: 0;
      //top: 0;
      //margin: 0 auto;
      //position: relative;
    }
  }
}
.theme--light{
  #new-chart{

  }
}
.theme--dark{
  #new-chart{

  }
}

</style>
<script>
import util from "../mixins/util";
import TabTitle from "../components/elements/TabTitle";
import Personal from "../components/new-chart/Personal";
import Transit from "../components/new-chart/Transit";
import Connection from "../components/new-chart/Connection";
import bodygraph from "../components/charts/bodygraph"
import {mapState} from "vuex";
import TransitChartMixin from "../mixins/TransitChartMixin";
import Cycle from "../components/new-chart/Cycle.vue";
export default{

  mixins: [util, TransitChartMixin],
  components:{TabTitle, bodygraph},
  computed:{
    ...mapState({
      chart: state => state.charts.chart,
      user: state => state.account.user
    }),

    tabs(){
      return [
        {
          show: true,
          icon:'icon-bodygraph-small-outline',
          key: 'personal',
          name:this.$t('pages.newChart.personal'),
          component: Personal,
          hash: 0
        },
        {
          show: true,
          icon:'icon-love-outline',
          key: 'connection',
          name:this.$t('pages.newChart.connection'),
          component: Connection,
          hash: 1
        },
        {
          show: true,
          icon:'icon-person-cross',
          key: 'transit',
          name:this.$t('pages.newChart.transit'),
          component: Transit,
          hash: 2
        },
        {
          show: true,
          icon:'icon-hourglass',
          name:this.$t('pages.newChart.justNow'),
          component: null,
          hash: 3,
          clickAction: () => {
            if(this.loading) return;
            this.loading = true;

            this.$router.push('charts/transit/just-now');
            this.loading = false;
          }
        },
        {
          show: this.user?.cycle_charts || false,
          icon:'icon-view-mode',
          name:this.$t('pages.newChart.cycle'),
          component: Cycle,
          hash: 4
        },
      ]
    }
  },

  created(){

    if(this.$route.query.tab) {
      this.currentHash = this.tabs.find(x => x.key === this.$route.query.tab).hash
    }
  },

  data(){
    return{
      hashes: ['#personal', '#connection', '#transit'],
      currentHash: 0,
      loading: false,
    }
  },
  mounted(){
    this.$store.dispatch('ui/setTitle', this.$t('pages.newChart.title')); // set mobile header title!
    this.applyUrlHash();
    this.$store.dispatch('charts/setChart', null);
  },
  methods:{
    tabSwitch(newIndex, _old, event){
      // THIS PREVENTS THE NAVIGATION FOR JUST NOW CHART. PLEASE UPDATE THE ID IF IT EVER CHANGES.
      if(newIndex === 3) event.preventDefault();
      else {
        this.$store.dispatch('charts/setChart', null);
      }
    },
    changeUpdatingMode(val){
      this.loading = val;
    }
  },
  beforeDestroy() {
    // reset...
    this.$store.commit('charts/SET_NEW_CHART_ANIMATION_CLASSES', {});
  }
}
</script>