<template>

  <div class="mbg-chart-picker">
    <label v-if="title">{{title}} <b-button v-if="isEditMode" class="p-0 ml-3" variant="link" @click="isEditMode= false">
      {{  $t('general.close') }}</b-button></label>

    <div v-if="!isEditMode && !value" class="mt-3">

      <b-button variant="dark" class="border-0" @click="isEditMode = !isEditMode" :style="`${backgroundColor ? 'background:' +backgroundColor : ''}`"><span class="icon-search mr-3"/> {{$t('general.select')}}</b-button>

    </div>

    <div v-if="!isEditMode && value" >
      <chart-autocomplete-item :item="value" class="selected-chart" :class="hasError ? 'border-error' :''">
        <div class="d-flex flex-wrap">
          <b-button variant="dark" size="sm" class="btn-small-block mx-auto" @click="isEditMode = true"> {{$t('general.change')}}</b-button>
          <slot></slot>
        </div>
      </chart-autocomplete-item>
    </div>

    <div v-show="isEditMode" class="picker chart-picker">
      <autocomplete
          ref="autocomplete-wrapper"
                  :items="chartTextState ? charts : recentChartsList"
                    input-class="select-chart-input"
                    :value="value"
                    autofocus
                    :get-label="getLabel"
                    :component-item='template'
                    :auto-select-one-item="false"
                    @item-selected="chartSelected"
                    :placeholder="$t('properties.searchChart')"
                    @change="updateCharts"
                    :disabled="isFetchingCharts">

      </autocomplete>
    </div>


    <p class="form-error-indicator mb-0 mt-0" v-if="hasError">
      <span v-for="error in validation.$errors" :key="error.message" class="d-block">
        {{formatMessage(error.$message)}}
      </span>
    </p>



  </div>
</template>

<script>
import Autocomplete from "@/components/structure/Autocomplete/Autocomplete.vue";
import ChartAutocompleteItem from "./partials/ChartAutocompleteItem";
import docTypes from "@/helpers/docTypes";
import {config} from "@/utils/config";
import RecentChartsListMixin from "@/mixins/RecentChartsListMixin";

export default{

  mixins: [RecentChartsListMixin],
  props:{
    showRecents: Boolean,
    value: Object,
    title: String,
    buttonTitle: String,
    backgroundColor: String,
    validation: Object,
    allTypes: {
      type: Boolean,
      default: false
    },

  },
  components:{ChartAutocompleteItem, Autocomplete},
  computed:{
    hasError(){
      return this.validation && this.validation.$error;
    },
    recentChartsList() {
      if (!this.showRecents) return [];
      const personalChart = this.$store.state.charts.personalChart ? {
        id: this.$store.state.charts.personalChart.id,
        name: this.$store.state.charts.personalChart.meta.name,
        docType: docTypes.RAVE_CHART,
        isCelebrity: false,
        authority: this.$store.state.charts.personalChart.chart.authority,
        definition: this.$store.state.charts.personalChart.chart.definition,
        type: this.$store.state.charts.personalChart.chart.type,
        profile: this.$store.state.charts.personalChart.chart.profile,
        isPersonalChart: true
      } : null;
      return [
          personalChart,
        ...this.recentCharts.filter(x => x.docType === docTypes.RAVE_CHART && !x.isCelebrity && x.id !== personalChart?.id)
      ].filter(x => !!x)
    }
  },

  data(){
    return{
      charts: [],
      chartTextState: '',
      template: ChartAutocompleteItem,
      isFetchingCharts: false,
      timer: null,
      isEditMode: false,
      libraryUrl: config.chartsUrl + '/api/charts/',
      currentChart: this.value
    }
  },
  mounted(){
    this.getRecentChartsList();
  },
  watch:{
    value(val){
      if(val) this.isEditMode = false;
    },
    // Handles focus on the input element after clicking edit or change or any action that makes it visible.
    isEditMode(val){
      if(val){
        this.$nextTick(()=>{
          const node = this.$refs['autocomplete-wrapper'].$el;

          const input = node.querySelector('.select-chart-input');
          if(input) input.focus();
        })
      }
    }
  },

  methods:{
    getLabel(item){
      return item ? item.name: '';
    },
    chartSelected(chart){
      this.$emit('input', chart);
      this.isEditMode = false;
    },
    updateCharts(text) {

      this.chartTextState = text;
      // if city is empty, we don't search. If country is empty, we again do not search
      // no reason to spam back-end with empty requests.
      if(!text || text === ''){
        this.clearResults();
        return;
      }
      // split the search if it includes the state name so we only search
      // by city name. Example: New York City (New York) -> New York City (search string)
      // debounce searching
      clearTimeout(this.timer);
      this.timer = setTimeout(()=>{
        if(!text) return;
        this.search = text;
        this.fetchCharts();
      }, 400);

    },
    clearResults(){
      this.charts = [];
      this.$emit('input', null);
    },
    fetchCharts(){
      this.loading = true;

      let params = {
        limit: 20,
        offset: 0,
        order: this.allTypes ? 'raveChartsFirst' : 'creationDateDesc',
        name: this.search
      }

      if (!this.allTypes) params['docType'] = docTypes.RAVE_CHART

      this.axios.get(this.libraryUrl, {params: params}).then(res=>{
        this.charts = res.data.rows;
      }).catch(()=>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }).finally(()=>{
        this.loading = false;
      });
    },

    formatMessage(message){
      return message.includes('errors.') ? this.$t(message) : message;
    }
  }
}
</script>