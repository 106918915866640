import docTypes from "../helpers/docTypes";
import {mapState} from "vuex";

export default {
    computed:{
        ...mapState({
            unlockedCharts: state => state.account.unlockedCharts,
            subscriptions: state => state.account.subscriptions
        }),
        getSubscriptionStatus(){
            const subscriptions = this.subscriptions.map(x => x.products.map(y => y.key)).flat();
            return {
                foundational: subscriptions.includes('foundational'),
                advanced: subscriptions.includes('advanced'),
                companionship: subscriptions.includes('companionship')
            }
        },
        isFoundationalUnlocked() {
            return this.getSubscriptionStatus.foundational || this.getUnlockStatus(this.chart).foundational
        },
        isPartnershipUnlocked() {
            return this.getSubscriptionStatus.companionship || this.getUnlockStatus(this.chart).companionship
        },
        isPropertiesUnlocked() {
            return this.isFoundationalUnlocked || this.isPartnershipUnlocked
        },
        getUnlockCategory(){
            if (this.chart) {
                if (this.isConnectionChart(this.chart)) return 'companionship'
                if (this.isTransitChart(this.chart)) return 'transit_tool'
            }
            return 'foundational'
        }
    },
    methods:{

        getInitials(name, isConnection = false) {
            try {
                if (!name) return;
                if (isConnection) {
                    const separator = name.includes('&') ? '&' : '-';
                    // doing some transformation on companionship charts to appear a bit more appealing.
                    return name.split(` ${separator} `).map(x => x.trim().replaceAll(separator, '').trim()).map((n) => n[0].toUpperCase()).slice(0, 3).join("&");
                }
                return name.split(" ").map((n) => n[0].toUpperCase()).slice(0, 3).join("");
            } catch (e) {
                return '-'
            }
        },

        getType(chart){
            let type = chart;
            if(!type) return false;
            if(chart.meta && chart.meta.type){
                type = chart.meta.type;
            }
            if(type.docType){
                type = type.docType
            }
            return type;
        },
        getUnlockStatus(chart) {
            let unlocks = this.unlockedCharts.filter(x => x.chart_id === chart?.id).map(x => x.purchase_type);
            return {
                foundational: unlocks.includes('foundational'),
                advanced: unlocks.includes('advanced'),
                companionship: unlocks.includes('companionship')
            }
        },

        isPersonalChart(chart) {
            return this.getType(chart) === docTypes.RAVE_CHART;
        },

        isConnectionChart(chart){
            return this.getType(chart) === docTypes.CONNECTION_CHART;
        },

        isCycleChart(chart) {
            return this.getType(chart) === docTypes.CYCLE_CHART;
        },

        isTransitChart(chart){
            return this.getType(chart) === docTypes.TRANSIT_CHART;
        },

        isPersonTransitChart(chart) {
            if(!chart) return false;
            return this.isTransitChart(chart) && chart.meta.person && chart.meta.person.id
        },

        isTransitOnlyChart(chart) {
            return this.isTransitChart(chart) && !this.isPersonTransitChart(chart)
        },

        isTempChart(chart) {
            return this.$route.name === 'temp-transit-chart' || this.$route.name === 'temp-transit-connection-chart' || chart.id?.startsWith('temp-') || !chart.id;
        },
    }
}