import centers from "../helpers/rave/constants/centers";
import channels from "../helpers/rave/constants/channels";

const getGateCenterHighlight = (gateId) => {
    if(gateId){
        let center = centers.centersList.find((x => x.gates.includes(gateId)));
        let centerClass = center?.name.toLowerCase();
        if(centerClass) return document.querySelector(`#centers > #${centerClass}`);
    }
    return null;
};

export default {
    data(){
        return{
            types: {
                gate: 'g',
                channel: 'c',
                center: '',
                planet: 'p'
            }
        }
    },
    methods: {
        highlightElement({type, id,
                             extras = undefined,
                             includePlanets = false,
                             maintainPlanetOrientation = false,
                             orientation = null}){
            let elements = [];
            if (type === this.types.gate){
                elements = [...document.querySelectorAll('#gates > g, #channels > path.defined, #centers > g'),]
            }

            if (type === this.types.center || type === this.types.channel){
                elements  = document.querySelectorAll('#gates > g, #channels > path,  #centers > g');
            }

            const docId = type + id;

            for(let elem of elements){
                if(elem.id !== docId) elem.classList.add('highlight-low')
            }

            let centerHighlights = [];

            let doc = document.getElementById(docId);
            // let mandalaDocs = document.querySelectorAll('.' + type + id);
            const mandalaDocs = [];
            if(extras && extras.channelGates){
                let extraDocs = document.querySelectorAll(`#g${extras.channelGates[0]}, #g${extras.channelGates[1]}`);
                for(let elem of extraDocs){
                    elem.classList.add('highlight-no-stroke');
                }
            }
            // highlight center that gate is on
            if(type === this.types.gate && getGateCenterHighlight(id)) {
                centerHighlights.push(getGateCenterHighlight(id));
            }
            // highlight both centers that channel collides.
            if(type === this.types.channel) {
                let gates = channels.find(x => x.id === id).gates;
                for(let gate of gates){
                    if(getGateCenterHighlight(gate)) centerHighlights.push(getGateCenterHighlight(gate))
                }
            }

            // Highlight planets
            if (includePlanets) {
                if(type === this.types.gate){
                    const selector = `.planet-box.g${id}${maintainPlanetOrientation ? '.'+orientation : ''}`
                    centerHighlights.push(...document.querySelectorAll(selector));
                }

                if(type === this.types.channel) {
                    centerHighlights.push(...[
                            ...document.querySelectorAll(`.planet-box.g${extras?.channelGates[0]}`),
                        ...document.querySelectorAll(`.planet-box.g${extras?.channelGates[1]}`),
                    ]);
                }
            }

            for(let elem of centerHighlights) elem.classList.add('highlight');

            if(doc) doc.classList.add('highlight');
            if(mandalaDocs.length > 0){
                for(let doc of mandalaDocs) doc.classList.add('highlight');
            }
        },

        planetHighlight(id, orientation, gates, chartId = 0){
            let mandalaSelectors  = `.mandala-wrapper .g${gates[0]}.ma-p-${id}`;
            if (gates[1]) mandalaSelectors += `, .mandala-wrapper .g${gates[1]}.ma-p-${id}`;

            // let inactiveDocs = document.querySelectorAll('#gates > g, #channels > path, #channels > rect, #centers > g, .planet-box');
            let inactiveDocs = document.querySelectorAll('.regular-bodygraph #gates > g, .regular-bodygraph #channels > path, .regular-bodygraph #channels > rect, .regular-bodygraph #centers > g, .advanced.planet-box, .mandala-wrapper .ma-g, .mandala-gate-symbols');
            let activeDocs = document.querySelectorAll(`#g${gates[0]} ${gates[1] >= 0? ',#g'+ gates[1] : ''}, #planet-${orientation || 0}-${id}-${chartId}, ${mandalaSelectors}, .mandala-gate-symbols.gs_${gates[0]}, .mandala-gate-symbols.gs_${gates[1]}`);

            let activeCenters = [];
            if(getGateCenterHighlight(gates[0])) activeCenters.push(getGateCenterHighlight(gates[0]));
            if(getGateCenterHighlight(gates[1])) activeCenters.push(getGateCenterHighlight(gates[1]));

            for(let elem of inactiveDocs){
                elem.classList.add('highlight-low')
            }
            for(let elem of [...activeCenters, ...activeDocs]){
                elem.classList.add('highlight')
            }
            const figure = document.querySelector('.not-mandala-wrapper #figure')
            // let figure = document.getElementById('figure');
            if (figure) figure.classList.add('highlight');
        },

        clearAllHighlights(){
            let docs = document.querySelectorAll('.highlight-low, .highlight, .highlight-no-stroke');

            for(let doc of docs){
                if(doc.classList.contains('highlight-low')) doc.classList.remove('highlight-low');
                if(doc.classList.contains('highlight-no-stroke')) doc.classList.remove('highlight-no-stroke')
                if(doc.classList.contains('highlight')) doc.classList.remove('highlight');
            }

        }
    }
}