// import {getLocalStorageItem} from "../helper";

import axios from "axios";
import {config} from "@/utils/config";

export const namespaced = true

const state = {
    checkout_type: 0,
    products: {
        singles: [],
        subscriptions: []
    },
    single_cart_items: [],
    subscription_cart_items: [],
    checkoutCompleted: false,
}

const getters = {}

const mutations = {

    setCheckoutType(state, type){
        state.checkout_type = type
    },
    setProducts(state, products){
        state.products.singles = products.singles;
        state.products.subscriptions = products.subscriptions;
    },

    setSingleCartItems(state, items){
        state.single_cart_items = items;
    },

    addSingleCartItem(state, item){
        if(state.single_cart_items.find(x => {
            return x.chart_id === item.chart_id && x.key === item.key
        })) return false;
        state.single_cart_items.push(item);
    },

    addSubscriptionCartItem(state, item) {
        if(state.subscription_cart_items.find(x => {
            return x.key === item.key
        })) return false;
        state.subscription_cart_items.push(item);
    },

    removeSubscriptionFromCart(state, item) {
        state.subscription_cart_items.splice(
            state.subscription_cart_items.findIndex(x => {
                return x.key === item.key
            }), 1)
    },

    removeSingleFromCart(state, item){
        state.single_cart_items.splice(
            state.single_cart_items.findIndex(x => {
                return x.chart_id === item.chart_id && x.key === item.key
            }), 1)
    },

    setSubscriptionCartItems(state, items){
        state.subscription_cart_items = items;
    },

    SET_CHECKOUT_COMPLETED(state, val){
        state.checkoutCompleted = val;
    },

    clearSubscriptionCart(state){
        state.subscription_cart_items = []
    },

    clearSingleCart(state){
        state.single_cart_items = [];
    },
}

const actions = {
    init({dispatch}){
        dispatch('loadProducts')
    },

    loadProducts({commit}){
        return axios.get(config.apiUrl +'/billing/products').then(res=>{
            commit('setProducts', res.data);
        }).catch(()=>{

        });
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}