<template>
  <div id="chart-list">
    <slot></slot>

    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <div class="mt-3">
          <b-skeleton width="95%" />
          <b-skeleton width="95%" />
          <b-skeleton width="90%" />
          <b-skeleton width="85%" />
        </div>
      </template>

      <div v-if="charts.length === 0" class="text-center">
        <no-charts-found />
      </div>

      <chart-item v-for="(chart, index) in charts" :chart="chart" :key="chart.id"
                  :show-select="!!multiSelect"
                  :is-selected="isSelected(chart.id)"
                  :index="index"
                  :total="charts.length"
                  :type="options.showType"
                  :profile="options.showProfile"
                  :definition="options.showDefinition"
                  :authority="options.showAuthority" :is-search="false"
                  show-edit-chart
                  @editChart="editChart"
                  @chartSelected="chartSelected"
      />

      <EditChart
                 @closed="closed"
                 @deleted="deleted" />

    </b-skeleton-wrapper>
  </div>

</template>

<style lang="scss">
#chart-list{
  .custom-checkbox{
    margin-right:1rem;
    flex-shrink:0;
  }
}
</style>

<script>

import ChartItem from "./ChartItem";
import NoChartsFound from "../global/NoChartsFound.vue";
import {mapState} from "vuex";
import EditChart from "@/components/structure/chart-sidebars/EditChart.vue";
import ChartPageMixin from "@/mixins/ChartPageMixin";

export default{
  components:{EditChart, NoChartsFound, ChartItem},
  mixins: [ChartPageMixin],
  props:{
    value: Array,
    charts: Array,
    tableOptions: Object,
    loading: Boolean,
    multiSelect: Boolean
  },
  computed:{
    ...mapState({
      options: state => state.ui.options
    }),
  },
  data(){
    return {
      selected: null
    }
  },
  methods:{
    isSelected(id) {
      return this.value.some(x => x.id === id)
    },
    chartSelected(val) {
      let list = this.value;
      if (val.isSelected){
        list.push(val)
      } else {
        list = list.filter(x => x.id !== val.id)
      }
      this.$emit('input', list);
    },

    deleted(){
      this.selected = null;
      this.$store.commit('ui/SET_SHOW_EDIT_CHART', false);
      this.$store.commit('charts/SET_CHART', null);
      this.$emit('refetch');
    },

    closed(){
      this.selected = null;
      this.$emit('updateEditedChart', this.$store.state.charts.chart);
      this.$store.commit('ui/SET_SHOW_EDIT_CHART', false);
      this.$store.commit('charts/SET_CHART', null);
    },

    editChart(val) {
      this.fetchDoc(val.id).then(chart => {
        this.selected = val.id;
        this.$store.commit('charts/SET_CHART', chart);
        this.$store.commit('ui/SET_SHOW_EDIT_CHART', true);
      })
    }
  }
}
</script>