export default{


    bodygraph: 'Standard',
    mandala: 'Rave Mandala',
    ai: 'Advanced Imaging',
    normal: 'Complete',
    cross: 'Incarnation cross',
    design: {
        rave: 'Design',
        connection: '{person1} Only'
    },
    personality: {
        rave: 'Personality',
        connection: '{person2} Only'
    },
}