<template>

  <div class="px-md-2">
    {{ product.name }}

    <div class="my-2 text-right">
      <b-button size="sm" :variant="isNewCreation ? 'link' :'primary'" @click="isNewCreation = !isNewCreation">{{ !isNewCreation ? $t('general.create') : $t('general.cancel')}}</b-button>

    </div>

    <div v-if="!isNewCreation">

      <mbg-text-input v-model="searchText" :debounce="300" :placeholder="$t('properties.searchChart')"
                      icon="icon-search"></mbg-text-input>

      <div>
        <chart-item v-for="(chart) in filteredCharts" :key="chart.id" :chart="chart" :is-search="true"
                    is-checkout
                    @chartItemClicked="$emit('addToCart', chart)"/>
      </div>
    </div>
    <div v-else>

      <b-overlay :show="loading" rounded="sm">
        <personal v-if="product.key === 'foundational' || product.key === 'advanced'"
                  cancel-redirect
                  hide-previews
                  @updating="val => loading = val"
                  @chartCreated="addNewChartToCart"
        />
        <connection v-else
                    cancel-redirect
                    hide-previews
                    @updating="val => loading = val"
                    @chartCreated="addNewChartToCart"
        />
      </b-overlay>
    </div>


  </div>

</template>

<script>

import {mapState} from "vuex";
import MbgTextInput from "../../elements/MbgTextInput";
import ChartLibraryMixin from "../../../mixins/ChartLibraryMixin";
import ChartItem from "../../library/ChartItem";
import documentTypes from "../../../helpers/docTypes";
import Personal from "../../new-chart/Personal.vue";
import Connection from "../../new-chart/Connection.vue";

export default {
  mixins: [ChartLibraryMixin],
  components: {Connection, Personal, ChartItem, MbgTextInput},
  props: {
    product: Object
  },
  computed: {

    ...mapState({
      unlockedCharts: state => state.account.unlockedCharts,
      cartItems: state => state.checkout.single_cart_items
    }),

    filteredCharts() {

      let unlockedChartIds = this.unlockedCharts.map(x => x.chart_id)
      // check cart items
      let cartChartIds = this.cartItems.map(x => x.chart_id);

      return this.charts.filter(x => {

        // quick escape if the chart is not included
        // not in the owned charts nor the cart
        if (!unlockedChartIds.includes(x.id) && !cartChartIds.includes(x.id)) return true;

        // check user owned charts.
        let ownedCharts = this.unlockedCharts.filter(y => y.chart_id === x.id);

        for (let ownedChart of ownedCharts) {
          // if we own this chart precisely in any way, we return false
          if (this.product.key === ownedChart.purchase_type) return false;

          if (ownedChart.purchase_type === "full_rave_chart") return false;
        }

        let itemAlreadyInCart = this.cartItems.find(y => {
          return y.chart_id === x.id && y.key === this.product.key
        });

        return !itemAlreadyInCart;


      })

    },
  },
  watch: {

    searchText(val) {
      // debounce
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.search = val;
      }, 300)

    },

    selectedDocType(val) {

      // empty val = clearing the list!
      if (val === -1) {
        this.searchFilters = [];
      } else if (documentTypes[val]) {
        // if we have a chart type element.

        this.searchFilters = [
          {
            prop: 'docType',
            value: [val]
          }
        ]
      } else {
        this.searchFilters = [] // for now we just clear the results
      }
      this.fetchCharts()

    }

  },
  created() {
    this.applySearchFilters();
    this.fetchCharts(); // initialize list.
  },
  data() {
    return {
      loading: false,
      timeout: null,
      searchText: null,
      documentTypes,
      selectedDocType: -1,
      searchFilters: [],
      isSearch: true, // super important variable that dictates how filters behave on the MIXIN
      isNewCreation: false,
    }
  },
  methods: {
    applySearchFilters() {
      if (!this.product) return;

      if (this.product.key === 'advanced' || this.product.key === 'foundational' || this.product.key === 'full_rave_chart') {
        this.searchFilters = [{
          prop: 'docType',
          value: [documentTypes.RAVE_CHART]
        }]
      }
      if (this.product.key === 'companionship') {
        this.searchFilters = [{
          prop: 'docType',
          value: [documentTypes.CONNECTION_CHART]
        }]
      }
    },

    addNewChartToCart(chart) {
      this.isNewCreation = false;
      const metadata = {
        name: chart.meta.name,
        id: chart.id
      }

      this.$emit('addToCart', metadata);
    }
  }
}
</script>