


export const createSingleCartItem =  (chart, item) => {

    return {
        chart_id: chart.id,
        name: chart.name,
        key: item.key,
        price: item.price,
        product: item
    }
}