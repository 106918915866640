<template>
  <div id="payment" class="below-header">
    <b-overlay :show="loading" rounded="sm">
      <div class="container">

        <div>
          <div v-if="!addNewCard" class="grid grid-cols-2 gap-10">
            <b-card v-for="card in cards" :key="card.id" class="d-inline-flex">
              <b-card-body class="d-inline-flex text-left w-100">
                <div class="flex-shrink-0 ">
                  <img :src="'/'+card.card.brand + '.svg'" width="35" class="mr-2"> <span class="ml-2">**** **** **** {{card.card.last4}}</span>
                  <span class="ml-2">{{card.card.exp_month}}/{{card.card.exp_year}}</span>
                </div>
                <div class="text-right ml-5 d-flex align-items-center">
                  <b-button size="xs" variant="danger" @click="toggleDeletePaymentMethod(card)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                         stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash">
                      <polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                  </b-button>
                </div>
              </b-card-body>
            </b-card>
          </div>



          <div class="mt-3">
            <b-button v-if="hasCard !== null && !addNewCard" variant="primary" size="sm" :disabled="addNewCard" @click="setupCardIntent">
              {{$t('billing.addCard')}}
            </b-button>
          </div>


          <b-card v-if="addNewCard">

            <b-card-body>

              <form id="payment-form" @submit.prevent="saveCard">
                <div id="save-card-element">
                  <!-- Elements will create form elements here -->
                </div>

                <div v-if="card" class="my-3">
                  {{ $t('billing.replace') }}
                </div>
                <div v-else class="my-3">
                  {{ $t('billing.save') }}
                </div>

                <div class="text-right my-3">
                  <button class="btn btn-primary" :disabled="savingCard">{{$t('billing.saveCard')}}</button>
                </div>

                <div v-if="error"  class="text-danger   py-2 px-3 rounded mt-3">
                  {{error}}
                </div>
              </form>



            </b-card-body>

          </b-card>


          <div v-if=" hasCard !== null && !hasCard && !addNewCard">

            <p class="font-light py-3">
              {{$t('billing.noCards')}}
            </p>

          </div>

          <b-modal ref="delete-card" :title="$t('billing.deleteCardTitle')"
                   @ok="deletePaymentMethod"
                   :ok-title="$t('billing.delete')">
            <p>{{$t('billing.deleteCardText')}}</p>
            <p><strong>Are you sure you want to proceed?</strong></p>
          </b-modal>

        </div>
      </div>
    </b-overlay>

  </div>
</template>

<script>
import {loadStripe} from '@stripe/stripe-js';

export default{



  computed:{
    getStripeColors(){
      return {
        colorPrimary: '#D14B49',
        colorBackground: this.darkMode ? '#1D1C1C' : '#f3f3f3',
        colorText: this.darkMode ? '#ffffff' : '#000000',
        borderRadius: '8px'
      }
    }
  },

  async mounted(){
    await this.setupStripe();
    this.getSavedCard();
  },

  watch:{
    darkMode(){
      this.setupCardStylingAndRender();
    }
  },

  data(){
    return{
      loading: false,
      hasCard: null,
      cards: [],

      stripe: null,
      elements: null,
      stripe_options: {
        clientSecret: null,
        appearance: {
          theme: 'none'
        }
      },
      addNewCard: false,
      setupIntent: null,
      savingCard: false,
      error: null,

      selectedForDeletionId: null,
    }
  },

  methods:{
    async setupStripe(){
      try{
        // eslint-disable-next-line no-undef
        this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK);
      }catch(e){
        console.log(e);
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }
    },

    getSavedCard(){
      this.loading = true;
      this.axios.get('/billing/stripe/saved-card').then(res=>{
        if(res.data.length === 0){
          this.hasCard = false;
          this.setupCardIntent();
          return;
        }
        this.hasCard=  true;
        this.cards = res.data;
      }).catch(e=>{
        console.error(e);
      }).finally(()=>{
        this.loading = false;
      })
    },

    setupCardStylingAndRender(){
      this.stripe_options.appearance.variables = {
        ...this.getStripeColors,
        spacingUnit: '4px'
      }
      this.elements = this.stripe.elements(this.stripe_options);


      // Create and mount the Payment Element
      const paymentElement = this.elements.create('payment');
      paymentElement.mount('#save-card-element');
    },
    setupCardIntent(){
      this.addNewCard = true;

      this.axios.post('/billing/stripe/save-card-intent').then(res=>{
        this.stripe_options.clientSecret = res.data;
        this.setupCardStylingAndRender();
      }).catch(()=>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      });

    },
    async saveCard(e){
      this.loading = true;
      this.error = null;
      this.savingCard = true;
      e.preventDefault();

      const result = await this.stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements: this.elements,
        redirect: "if_required",
        confirmParams: {
          return_url: window.location.origin + '/account/tab=billing&category=cards&setup-complete=true',
        }
      });

      if (result.error) {
        this.error = result.error.message;

      } else {
        this.axios.post('/billing/stripe/attach-card', {paymentMethodId: result.setupIntent.payment_method}).then(() => {
          this.error = null;
          this.addNewCard = false;
          this.getSavedCard();
        })

      }
      this.savingCard = false;

    },

    toggleDeletePaymentMethod(card) {
      this.$refs['delete-card'].show();
      this.selectedForDeletionId = card.id;
    },

    deletePaymentMethod(){
      if(!this.selectedForDeletionId) return;
      this.loading = true;
      this.axios.delete('/billing/stripe/card/' + this.selectedForDeletionId).then(() =>{
        this.getSavedCard();
      }).finally(()=>{
        this.loading = false;
      });
    },
  }


}
</script>