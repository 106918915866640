<template>
  <div class="px-4">
    <dynamic-zone-renderer v-if="content" :components="content.components" :add-paddings="false"/>

  </div>
 </template>

<script>

import DynamicZoneRenderer from "../../renderer/DynamicZoneRenderer";
import AnalysisQueryMixin from "../../../mixins/AnalysisQueryMixin";

export default{
  mixins: [AnalysisQueryMixin],
  components: {DynamicZoneRenderer},
  data(){
    return{
      audioKey: 0,
      tab: 0,
      content: null,
    }
  },

  created(){
    this.getConnectionIntroduction();
  },

  methods:{
    getConnectionIntroduction(){
      let url = '/analysis/introductions/introduction.partnership-analysis'
      this.getContent('content', url);
    },
  }
}
</script>