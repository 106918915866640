<template>
  <div class="px-4">
    <dynamic-zone-renderer v-if="content" :components="content.components" :add-paddings="false"></dynamic-zone-renderer>
    <div>
      <div v-for="channel in filteredChannels" :key="channel.channel">
        <name-linking-dynamic-zone-renderer
            v-if="channel && channelsContent[channel.channel]"
            :title="channel.name"
            :channel="channel"
            :components="channelsContent[channel.channel].components"
            :type="`${$t('charts.analysis.connection.activation.compromise')} (${channel.dominant} >> ${channel.dominee})`"
            :planets="chart?.chart?.planets || []"
            :names="[chart.meta.person1.name, chart.meta.person2.name]"
            is-compromise
        />
      </div>
      <div v-if="filteredChannels.length === 0 && !fetching" class="font-500">
        There are no compromise connections in this chart.
      </div>
    </div>
  </div>
</template>

<script>

import DynamicZoneRenderer from "../../renderer/DynamicZoneRenderer";
import AnalysisQueryMixin from "../../../mixins/AnalysisQueryMixin";
import {mapState} from "vuex";
import ChannelsSetupMixin from "../../../mixins/ChannelsSetupMixin";
import channelCompositionStates from "../../../helpers/rave/constants/channelCompositionStates";
import Vue from "vue";
import channelConstants from "../../../helpers/rave/constants/channels";
import NameLinkingDynamicZoneRenderer from "../../renderer/NameLinkingDynamicZoneRenderer.vue";

export default {
  mixins: [AnalysisQueryMixin, ChannelsSetupMixin],
  components: {NameLinkingDynamicZoneRenderer, DynamicZoneRenderer},
  data(){
    return{
      audioKey: 0,
      tab: 0,
      content: null,
      channelsContent: {}
    }
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),

    filteredChannels(){
      return this.channels.filter(x => x.state === channelCompositionStates.COMPROMISE);
    }
  },
  created(){
    this.getIntroduction();
    this.setupChannelContent();
    this.getChannelContent();
  },

  methods:{
    getIntroduction(){
      let url = '/analysis/introductions/introduction.compromise'
      this.getContent('content', url);
    },
    getChannelContent(){
      for(let channel of this.filteredChannels){
        Vue.set(this.channelsContent, channel.channel, {});

        const chObject = channelConstants.find(x => x.id === channel.channel);

        let gateToQuery = null;

        // we search the gate that the non-dominant user has and query with this gate (for example on 53-42 channel, user has 42 gate)
        for(let gate of chObject.gates){
          let gt = this.chart.chart.planets.find(x => x.gate === gate && x.chartId !== channel.dominantChartId);

          if(gt) gateToQuery = gt.gate;

        }
        let url = '/compromises/' + gateToQuery + '.'+ channel.channel;
        this.getContent(('channelsContent.'+channel.channel), url, true);
      }
    }
  }
}
</script>