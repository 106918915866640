<template>
  <div v-if="tab_1 || tab_2" class="px-4">
    <inner-tabs-renderer :id="0"
                         type="advanced"
                         :tabs="tabs"
                         :total-outer-tabs="totalTabs"
                         @prevSection="$emit('prevSection')"
                         @nextSection="$emit('nextSection')"
                         @innerTabChanged="(val) => $emit('innerTabChanged', val)" />
  </div>
</template>

<script>

import DynamicZoneRenderer from "../../../renderer/DynamicZoneRenderer";
import AnalysisQueryMixin from "../../../../mixins/AnalysisQueryMixin";
import InnerTabsRenderer from "../../../renderer/InnerTabsRenderer.vue";

export default{
  components: {InnerTabsRenderer},
  mixins: [AnalysisQueryMixin],
  computed:{
    tabs(){
      return [
        {
          id: 0,
          title: 'Transformation',
          shouldRender: !!this.tab_1,
          component: DynamicZoneRenderer,
          props: {
            components: this.tab_1 ? this.tab_1.components : [],
            addPaddings: false,
          }
        },
        {
          id: 1,
          title: 'Variables',
          shouldRender: !!this.tab_2,
          component: DynamicZoneRenderer,
          props: {
            components: this.tab_2 ? this.tab_2.components : [],
            addPaddings: false,
          }
        }
      ]
    }
  },
  data(){
    return{
      tab: 0,
      audioKey: 0,
      tab_1: null,
      tab_2: null
    }
  },
   created(){
     this.getContent('tab_1', '/analysis/introductions/introduction.advanced.1.1');
     this.getContent('tab_2', '/analysis/introductions/introduction.advanced.1.2');
   }
}
</script>