<template>
  <div class="px-4">
    <dynamic-zone-renderer v-if="content" :components="content.components" :add-paddings="false"></dynamic-zone-renderer>
    <div>
      <div v-for="channel in filteredChannels" :key="channel.channel">

        <dynamic-zone-renderer v-if="channel"
                               :title="channel.name" :components="channelsContent[channel.channel].components"
                               :is-toggleable="true" :add-paddings="false"></dynamic-zone-renderer>
      </div>
      <div v-if="filteredChannels.length === 0 && !fetching" class="font-500">
        There are no companionship connections in this chart.
      </div>
    </div>
  </div>
</template>

<script>

import DynamicZoneRenderer from "../../renderer/DynamicZoneRenderer";
import AnalysisQueryMixin from "../../../mixins/AnalysisQueryMixin";
import {mapState} from "vuex";
import ChannelsSetupMixin from "../../../mixins/ChannelsSetupMixin";
import channelCompositionStates from "../../../helpers/rave/constants/channelCompositionStates";
import Vue from "vue";

export default{
  mixins: [AnalysisQueryMixin, ChannelsSetupMixin],
  components: {DynamicZoneRenderer},
  data(){
    return{
      audioKey: 0,
      tab: 0,
      content: null,
      channelsContent: {}
    }
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),

    filteredChannels(){
      return this.channels.filter(x => x.state === channelCompositionStates.COMPANIONSHIP);
    }
  },
  created(){
    this.getIntroduction();
    this.setupChannelContent();
    this.getChannelContent();
  },

  methods:{
    getIntroduction(){
      let url = '/analysis/introductions/introduction.companionship'
      this.getContent('content', url);
    },
    getChannelContent(){
      for(let channel of this.filteredChannels){
        Vue.set(this.channelsContent, channel.channel, {});
        let url = '/companionships/' + channel.channel;
        this.getContent(('channelsContent.'+channel.channel), url, true);
      }
    }
  }
}
</script>