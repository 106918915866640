<template>
  <side-extended-panel id="download-image-chart"
                       :key="chartId"
                       :close-btn-text="$t('owl.close')"
                       :title="`${$t('charts.properties.image')} - ${chartName}`"
                       :visible="show"
                       size="small"
                       @close="close">


    <div class="py-5">
      <div class="grid gap-10">
        <mbg-text-input v-model="name" :title="$t('charts.properties.imageName')" show-label/>
        <mbg-select v-model="background" :options="[
                         {
                          text: this.$t('charts.properties.regular'),
                          value: 'regular'
                        },
                        {
                          text: this.$t('charts.properties.transparent'),
                          value: 'transparent'
                        },

                    ]"
                    :title="$t('charts.properties.imageBackground')"/>


      </div>
      <div class="mt-4">
        <b-button :disabled="isSaving" variant="primary" @click="print">Download</b-button>
      </div>
    </div>
  </side-extended-panel>

</template>

<style lang="scss">
</style>

<script>

import SideExtendedPanel from "../SideExtendedPanel.vue";
import {mapState} from "vuex";

import MbgTextInput from "../../elements/MbgTextInput.vue";
import MbgSelect from "../../elements/MbgSelect.vue";
import ChartUtil from "../../../mixins/ChartUtil";
import {config} from "../../../utils/config";

const downloadImage = async (
    imageSrc,
    nameOfDownload = 'my-chart.webp',
) => {
  const response = await fetch(imageSrc);
  const blobImage = await response.blob();
  const href = URL.createObjectURL(blobImage);
  const anchorElement = document.createElement('a');
  anchorElement.href = href;
  anchorElement.download = nameOfDownload;
  document.body.appendChild(anchorElement);
  anchorElement.click();
  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(href);
}

export default {
  components: {MbgSelect, MbgTextInput, SideExtendedPanel},
  mixins: [ChartUtil],
  props: {
    chart: Object,
    chartId: String,
    chartName: String,
  },
  computed: {
    ...mapState({
      show: state => state.ui.showDownloadImage,
      showMandala: state => state.ui.showMandala,
      showAI: state => state.ui.singleChart.aiEnabled,
      viewMode: state => state.ui.viewMode
    }),
    url() {
      return `${config.clientUrl}/public/${this.chartId}`
    }
  },

  watch: {
    chartName() {
      this.name = this.chartName;
    }
  },
  data() {
    return {
      isSaving: false,
      name: this.chartName,
      background: 'regular'
    }
  },
  methods: {
    close() {
      this.$store.commit('ui/SET_SHOW_DOWNLOAD_IMAGE', false);
    },

    print() {
      this.isSaving = true;

      const query = [];

      if(this.background === 'transparent') query.push('transparent=true');
      if(this.showAI) query.push('showAI=true');
      if(this.showMandala) query.push('showMandala=true')
      if(this.darkMode) query.push('darkMode=true');
      if(this.viewMode !== 'normal') query.push('viewMode=' + this.viewMode);

      const queryString = query.length > 0 ? `?${query.join('&')}` : '';

      this.axios.get(`${config.imagesApi}/mbg/images/${this.chart.id}${queryString}`).then(res =>{
        downloadImage(res.data, this.name + '.webp');
      }).finally(()=>{
        this.isSaving = false;
      });
    }
  }

}
</script>
