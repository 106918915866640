<template>
  <div id="library">
    <div class="row m-0 p-0">
      <div class="col-12 col-md-4 col-lg-3 filters-wrapper" :class="showMobileFilters ? '' :'desktop-version'">
        <filters @fetch="fetchCharts" />
      </div>
      <div class="col-12 col-md-8 col-lg-9 p-2 px-md-3 px-lg-5 py-md-4 mt-0">
        <chart-list v-model="selectedCharts" :charts="charts" :loading="loading" class="mt-3 mb-2 mb-md-4"
                    :multi-select="showMultiSelect"
                    @updateEditedChart="updateEditedChart"
                    @refetch="fetchCharts"
        >
          <div class="d-sm-flex gap-3 justify-content-between">
            <div class="d-flex gap-5 mb-3 justify-content-between justify-content-sm-start align-items-center ">
              <b-dropdown v-show="showMultiSelect" id="multi-select-actions" :text="$t('general.actions')"
                          class="mr-md-0 text-sm btn btn-sm p-0 mb-0 pt-0 mt-0 multi-select-btn"
                          style="padding-right:0!important;padding-top:0!important"
                          left
                          variant="full-white">
                <b-dropdown-item :disabled="selectedCharts.length === 0"  @click="triggerDeletion">
                  Delete selected
                </b-dropdown-item>
                <b-dropdown-item :disabled="!showCreateConnection"  @click="createConnection">
                  Create Connection Chart
                </b-dropdown-item>
                <b-dropdown-divider/>
                <b-dropdown-item @click="selectAll">
                  Select All
                </b-dropdown-item>
                <b-dropdown-item @click="deselectAll">
                  Unselect All
                </b-dropdown-item>
              </b-dropdown>

              <b-button class="flex-shrink-0 mr-3" :variant="showMultiSelect ? 'link' : 'dark'" size="sm" @click="showMultiSelect = !showMultiSelect">
                {{ showMultiSelect ? $t('general.cancel') : $t('general.bulkActions') }}
              </b-button>

              <b-button variant="white" size="sm" @click="$store.commit('ui/SET_SHOW_IMPORT_CHARTS', true)"
                        v-b-tooltip.hover :title="$t('imports.title')"
                        class="h-100 pt-2 pb-0"><span class="icon-download" />
              </b-button>
            </div>
            <div class="d-flex align-items-center mb-3 gap-5 library-right-actions">

              <display-dropdown />
              <b-form-select v-model="tableOptions.limit" class="w-100  text-sm" :options="paginationOptions" />
              <b-form-select v-model="tableOptions.order" class="w-100  text-sm"
                             style="min-width:200px"
                             :options="formattedSortOptions"/>
            </div>
          </div>
        </chart-list>

        <div v-if="charts.length > 0" class="row justify-content-between align-items-center mb-5 mb-md-0">
          <div class="col-12 col-md-6 justify-content-center justify-content-md-start d-flex order-2 order-md-1">
            <div class="d-flex align-items-center">
              Showing {{ from }}-{{ to }} of {{tableOptions.totalRows}} total charts

               <span v-if="showMultiSelect" class="pl-1"> ({{ selectedCharts.length }} selected)</span>
            </div>
          </div>

          <b-pagination
              v-if="Math.ceil(tableOptions.totalRows / tableOptions.limit) > 1"
              v-model="tableOptions.page"
              :total-rows="tableOptions.totalRows"
              :per-page="tableOptions.limit"
              pills
              class="mb-0 col-12 col-md-6 justify-content-center justify-content-md-end order-md-2"
          />
        </div>

      </div>
    </div>

    <div class="mobile-version bottom-mobile-actions">
      <b-button :variant="showMobileFilters ? 'outline-primary' :'primary'" @click="showMobileFilters=!showMobileFilters">
        <p v-if="!showMobileFilters" class="mb-0">Filters <span class="icon-justify text-sm"></span></p>
        <p v-else class="mb-0">
         {{ $t('general.close') }} <span class="icon-close text-sm"></span>
       </p>
      </b-button>
    </div>

    <b-modal ref="delete-chart" ok-title="Delete Charts"
             :title="`Are you sure you want to delete these ${selectedCharts.length} charts?`"
             @ok="deleteSelected"
    >
      <p>
        If you delete the charts, there is no way to recover them.
        <strong>Charts that have an analysis unlocked cannot be deleted.</strong>
      </p>
      <p>
        <strong>
          Are you sure you want to proceed?
        </strong>
      </p>
    </b-modal>

  </div>
</template>

<style lang="scss">
#library{

  .multi-select-btn {
    button {
      padding:3px 15px !important;

      &.dropdown-toggle::after {
        margin-left: 10px;
      }
    }
  }
  .filters-wrapper{
    margin-top:-110px;
    padding: 130px 30px 30px 30px;
    border-radius: 0px 0px 30px 30px;

    @media(min-width:767px){
      min-height:100vh;
    }

    padding-left:10px!important;
    padding-right:10px!important;
    @media(min-width:1400px){
      padding-left: 3rem!important;
      padding-right: 3rem!important;
    }

    @media(max-width:767px){
      border-radius: 30px 30px 0 0;
      margin-top:0;
      padding:30px 15px;
      position:fixed;
      bottom:0;
      left:0;
      right:0;
      top:200px;
      overflow-y:auto;

      #library-filters{
        margin-bottom:50px;
        //height:100%;
      }
      z-index:4;
    }


  }

  .bottom-mobile-actions{
    position:fixed;
    bottom:5px;
    width:100%;
    z-index:5;
    text-align: center;
  }

}
.theme--light{
  #library{
    .filters-wrapper{
      background: #FFFFFF;
      box-shadow: 0px 10px 25px rgba(24, 28, 36, 0.05);
    }
  }
}
.theme--dark{
  #library{
    .filters-wrapper{
      background: $bg-dark;
    }
    #multi-select-actions {
      .dropdown-menu {
        //background: $bg-dark!important;
        //box-shadow: 0px 10px 25px rgba(24, 28, 36, 0.05);
        .dropdown-item.disabled{
          opacity:0.4;
        }
      }
    }
  }
}
</style>

<script>
import ChartList from "../../components/library/ChartList";
import ChartLibraryMixin from "../../mixins/ChartLibraryMixin";
import Filters from "../../components/library/Filters";
import {mapState} from "vuex";
import ChartPageMixin from "@/mixins/ChartPageMixin";
import DocTypes from "@/helpers/docTypes";
import ChartCreationMixin from "@/mixins/ChartCreationMixin";
import DisplayDropdown from "@/components/library/DisplayDropdown.vue";
export default{

  mixins:[ChartLibraryMixin, ChartPageMixin, ChartCreationMixin],
  components:{DisplayDropdown, ChartList, Filters},
  created(){
    // handle query search.
    if(this.$route.query.search){
      this.$store.commit('ui/SET_SEARCH', {
        active: true,
        keyword: this.$route.query.search
      })
    }
    this.fetchCharts();
    this.$store.dispatch('ui/setTitle', this.$t('header.menu.library')); // set mobile
  },
  computed:{
    ...mapState({
      searchState: state => state.ui.search,
      importCompleted: state => state.charts.importCompleted
    }),
    formattedSortOptions(){
      return this.sortOptions.map(x => ({value: x.value, text: this.$t(x.text)}))
    },

    from(){
      return (this.tableOptions.page-1) * this.tableOptions.limit + 1
    },
    to(){
      if (this.tableOptions.page * this.tableOptions.limit > this.tableOptions.totalRows)
        return this.tableOptions.totalRows;

      return this.tableOptions.page * this.tableOptions.limit;
    },

    // we only enable creation of connection chart
    // if we have bulk-selected only two charts, and both are personal.
    showCreateConnection() {
      return this.selectedCharts.length === 2
           && this.selectedCharts.filter(x => x.docType === DocTypes.RAVE_CHART).length === 2;
    }
  },
  watch:{
    searchState(val){
      if(val.active) this.search = val.keyword;
      else this.search= null;
    },
    charts(){
      this.$nextTick(()=>{
        window.scrollTo(0,0);
      })
    },
    importCompleted(val){
      if(val) this.fetchCharts();
    }
  },
  data(){
    return{
      loading: false,
      showMobileFilters: false,
      showMultiSelect: false,
      selectedCharts: [],
      showDeletionModal: false,
    }
  },
  methods:{
    triggerDeletion(){
      this.$refs['delete-chart'].show();
    },
    deleteSelected(){
      this.deleteMultiple(this.selectedCharts.map(x => x.id)).then(() => {
        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.updated')});
        this.fetchCharts();
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    },
    selectAll() {
      this.selectedCharts = [...this.selectedCharts, ...structuredClone(this.charts)].filter((value, index, self) =>
              index === self.findIndex((t) => (
                  t.id === value.id
            ))
      )
    },
    deselectAll(){
      this.selectedCharts = [];
    },
    createConnection() {
      if (!this.showCreateConnection) return;
      const chart1 = this.selectedCharts[0];
      const chart2 = this.selectedCharts[1];
      this.createConnectionChart({
        docIds: [chart1.id, chart2.id],
        name: `${chart1.name} & ${chart2.name}`,
        autosave: true
      }).then( (chart) => {
        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.created')});
        this.redirectTo('single-chart', null, {chartId: chart.id});
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    },

    updateEditedChart(chart) {
      const libChart = this.charts.find(x => x.id === chart.id);
      if (!libChart) return;
      libChart.name = chart.meta.name;
      libChart.authority = chart.chart.authority;
      libChart.definition = chart.chart.definition;
      libChart.profile = chart.chart.profile;
      libChart.type = chart.chart.type;
      libChart.tags = chart.meta.tags;
    }
  }
}


</script>