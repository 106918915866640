<template>

  <div id="gates-of-love" class="px-4">
    <b-tabs v-model="tab" class="tabs-primary-underlined light-tab-titles " fill lazy>
      <b-tab :title="$t('hd.properties.introduction')" class="">
        <dynamic-zone-renderer v-if="intro" :add-paddings="false" :components="intro.components"/>
      </b-tab>
      <b-tab :title="$t('charts.analysis.connection.gatesOfLove.transcendence')">
        <dynamic-zone-renderer v-if="transcendenceIntro" :add-paddings="false"
                               :components="transcendenceIntro.components" :title="$t('hd.properties.introduction')" is-toggleable/>
        <gates-of-love-list
            :chart-a-name="chart.meta.person1.name"
            :chart-b-name="chart.meta.person2.name"
            :chart-a="chartA.transcendent"
            :chart-b="chartB.transcendent"
            :content="general.filter(x => !!x)"
            :is-personal="false"/>
      </b-tab>
      <b-tab :title="$t('charts.analysis.connection.gatesOfLove.personal')">
        <dynamic-zone-renderer v-if="personalIntro" :add-paddings="false"
                               :components="personalIntro.components" :title="$t('hd.properties.introduction')" is-toggleable/>
        <gates-of-love-list
            :chart-a-name="chart.meta.person1.name"
            :chart-b-name="chart.meta.person2.name"
            :chart-a="chartA.personal"
            :chart-b="chartB.personal"
            :content="general.filter(x => !!x)"
            :is-personal="true"/>

      </b-tab>
    </b-tabs>
  </div>
</template>

<script>

import {mapState} from "vuex";
import AnalysisQueryMixin from "../../../mixins/AnalysisQueryMixin";
import DynamicZoneRenderer from "../../renderer/DynamicZoneRenderer.vue";
import {getElectromagneticGates} from "../../../helpers/rave/constants/gatesOfLove";
import GatesOfLoveList from "./partials/GatesOfLoveList.vue";

export default {
  components: {GatesOfLoveList, DynamicZoneRenderer},
  mixins: [AnalysisQueryMixin],
  data() {
    return {
      tab: 0,
      audioKey: 0,
      intro: null,
      transcendenceIntro: null,
      personalIntro: null,
      chartA: [],
      chartB: [],
      general: [],
      gatesOfLoveContent: {}
    }
  },
  computed: {
    ...mapState({
      chart: state => state.charts.chart
    })
  },
  created() {
    this.parseGatesOfLove();
    this.getContent('intro', '/analysis/introductions/introduction.love');
    this.getContent('transcendenceIntro', '/analysis/introductions/introduction.love.transcendent');
    this.getContent('personalIntro', '/analysis/introductions/introduction.love.personal');
    this.fetchGateContent();
  },
  methods: {
    parseGatesOfLove() {
      const personOnePlanets = this.chart.chart.planets.filter(x => x.chartId === 0);
      const personTwoPlanets = this.chart.chart.planets.filter(x => x.chartId === 1);

      this.chartA = getElectromagneticGates(personOnePlanets, personTwoPlanets, this.chart.chart);
      this.chartB = getElectromagneticGates(personTwoPlanets, personOnePlanets, this.chart.chart);
    },

    fetchLoveGate(gate) {
      this.getArrayContent('general', `/gates-of-love/${gate}`);
    },

    fetchGateContent() {
      let uniqueContent = new Set();
      for (let gate of [...this.chartA.personal, ...this.chartA.transcendent, ...this.chartB.personal, ...this.chartB.transcendent]) {
        uniqueContent.add(gate.gate + '.' + (gate.isPersonal ? 'personal' : 'transcendence'));
      }

      for (let gate of [...uniqueContent]) {
        this.fetchLoveGate(gate)
      }
    }
  }
}
</script>