<template>
  <side-extended-panel id="gift-chart"
                       :close-btn-text="$t('owl.close')"
                       :visible="show"
                       size="x-small"
                       extra-container-classes="p-0"
                       @close="close">

    <template #header>
      <new-header :title="$t('gifts.title')" icon="icon-gift"/>
    </template>
    <div class="px-4">
      <div class="mb-4">
        <p v-if="!showMore">{{ $t(`gifts.${personal ? 'personal' : 'connection'}.paragraph`) }}</p>
        <div v-else v-html="$t(`gifts.${personal ? 'personal' : 'connection'}.extended`)"/>
        <b-button class="btn-primary-link" variant="link" @click="showMore = !showMore">
          {{ showMore ? $t('general.close') : $t('general.showMore') }}
        </b-button>
      </div>

      <div v-for="(item, index) in boxes" :key="item.type" :title="item.title" class="card mb-3">
        <div class="card-body">
          <div class="card-title"><h5>{{item.title}}</h5></div>

          <div v-if="item.isGift">
            <p class="mb-1"><span class="font-500">Gift Received</span> — {{$t('gifts.received.text')}} </p>
          </div>
          <div v-else-if="!item.isPurchased">
            <div class="gift-text-xs" v-html="item.text" />

            <div class="d-flex justify-content-between align-items-center">
              <b-button variant="primary" size="xs" @click="unlock(item)">{{$t('billing.unlock')}} {{$t('billing.unlockTypes.'+item.type)}}</b-button>
              <p class="gift-text-xs mb-0">${{item.product.price}}</p>
            </div>
          </div>
          <div v-else-if="item.isGiftClaimed">
            <p class="mb-0">
              {{$t('gifts.received.thanks', { name: item.isGiftClaimed.name }) }}
            </p>
          </div>
          <div v-else>
            <div v-html="$t(`gifts.${personal ? 'personal' : 'connection'}.link`)" class="gift-text-xs"/>

            <div class="d-flex justify-content-between align-items-center mt-3">
              <b-button variant="outline-white" size="xs" class="" :disabled="item.isFreePurchase" @click="copyLink(item, index)">
                <span v-if="copied[index]">
                  <span class="icon-check"/> {{$t('general.copied')}}
                </span>
                  <span v-else>
                  {{$t('gifts.copyLink')}}
                </span>
              </b-button>

              <div class=" text-sm mb-0 color-green"> {{$t('gifts.purchased')}}</div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </side-extended-panel>

</template>

<style lang="scss">

#share-input{
  input{
    opacity:1!important;
  }
}
</style>

<script>

import SideExtendedPanel from "../SideExtendedPanel.vue";
import {mapState} from "vuex";

import ChartUtil from "@/mixins/ChartUtil";
import {createSingleCartItem} from "@/helpers/checkout_helper";
import GiftMixin from "@/mixins/GiftMixin";
import NewHeader from "@/components/structure/chart-sidebars/partials/NewHeader.vue";

export default{
  components: {NewHeader, SideExtendedPanel },
  mixins: [ChartUtil, GiftMixin],
  props:{
    personal: Boolean,
    chartId: String,
    name: String,
  },
  computed:{
    ...mapState({
      show: state => state.ui.showGiftChart,
      products: state => state.checkout.products
    }),

    purchaseIds(){
      return this.unlockedCharts.filter(x => x.chart_id === this.chartId).map(x => x.userSinglePurchaseId).filter(x => !!x);
    },

    getGiftStatus() {
      let unlocks = this.unlockedCharts.filter(x => x.chart_id === this.chartId);
      return {
        foundational: unlocks.find(x => x.purchase_type === 'foundational'),
        advanced: unlocks.find(x => x.purchase_type === 'advanced'),
        companionship: unlocks.find(x => x.purchase_type === 'companionship'),
      }
    },

    boxes() {
      // we check the status of the chart.
      const status = this.getUnlockStatus({id: this.chartId});
      const giftStatus = this.getGiftStatus;

      for (const key of Object.values(giftStatus)) {
        if (!key) continue;
        key.purchase = this.purchases.find(x => x.id === key.userSinglePurchaseId);

        const cartItem = key.purchase?.metadata?.cart?.find(x => x.chart_id === this.chartId);
        key.isGiftClaimed = cartItem?.giftedChartId ? cartItem.recipient: null;
      }

      if (!this.personal) {
        return [{
          type: 'companionship',
          unlockCode: '2',
          title: this.$t('billing.products.names.companionship'),
          text: this.$t('billing.products.unlocks.companionship'),
          isGift: !!giftStatus.companionship?.metadata?.isGift,
          isPurchased: status.companionship,
          product: this.products.singles.find(x => x.key === 'companionship'),
          isFreePurchase: !giftStatus.companionship?.purchase || giftStatus.companionship?.purchase.provider === 2,
          purchase: giftStatus.companionship?.purchase,
          isGiftClaimed: giftStatus.companionship?.isGiftClaimed,
          chartId: this.chartId
        }]
      }

      return [
        {
          type: 'foundational',
          unlockCode: '0',
          title: this.$t('billing.products.names.foundational'),
          text: this.$t('billing.products.unlocks.foundational'),
          isGift: !!giftStatus.foundational?.metadata?.isGift,
          isPurchased: status.foundational,
          product: this.products.singles.find(x => x.key === 'foundational'),
          isFreePurchase: !giftStatus.foundational?.purchase || giftStatus.foundational?.purchase.provider === 2,
          purchase: giftStatus.foundational?.purchase,
          isGiftClaimed: giftStatus.foundational?.isGiftClaimed,
          chartId: this.chartId
        },
        {
          type: 'advanced',
          unlockCode: '01',
          title: this.$t('billing.products.names.advanced'),
          text: this.$t('billing.products.unlocks.advanced'),
          isGift: !!giftStatus.advanced?.metadata?.isGift,
          isPurchased: status.advanced,
          product: this.products.singles.find(x => x.key === 'advanced'),
          isFreePurchase: !giftStatus.advanced?.purchase || giftStatus.advanced?.purchase.provider === 2,
          purchase: giftStatus.advanced?.purchase,
          isGiftClaimed: giftStatus.advanced?.isGiftClaimed,
          chartId: this.chartId
        },
      ]
    }
  },
  watch: {
    purchaseIds(){
      this.getPurchases()
    }
  },
  mounted(){
    this.getPurchases()
  },
  data(){
    return{
      showMore: false,
      purchases: [],
      loading: false,
    }
  },
  methods:{
    getPurchases(){
      if (this.purchaseIds.length === 0) return;
      if (this.loading) return;
      this.loading = true;
      this.axios.get('/billing/gifts/purchases', { params: {
        purchaseIds: this.purchaseIds
      }} ).then(res =>{
        this.purchases = res.data;
      }).catch(() => {
      }).finally(() => { this.loading = false });
    },
    close(){
      this.$store.commit('ui/SET_SHOW_GIFT_CHART', false);
    },
    unlock(item){
      this.$store.commit('checkout/setCheckoutType', this.CheckoutTypes.SINGLE);
      this.$store.commit('checkout/addSingleCartItem', createSingleCartItem({
        id: this.chartId,
        name: this.name
      }, item.product));
      this.$router.push({name: 'checkout'});
    },
  }

}
</script>