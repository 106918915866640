<template>
  <div id="rave-chart-view-wrapper"
       :class="`${showMandala ? 'mandala-chart-wrapper' : 'not-mandala-wrapper'} view-mode-${viewMode}`">
    <complete-chart-canvas v-if="!showMandala" :chart="chart" :interactive="interactive" :view-mode="viewMode" />
    <!--  Mandala Mode      -->
    <div v-else class="row chart-wrap overflow-hidden">

      <hd-mandala :chart="chart" :interactive="interactive" dom-ref-id="mandala">
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="25%"
            y="22.5%"
            width="50%"
            height="50%"
            viewBox="0 0 2000 2000"
            enable-background="new 0 0 2000 2000"
            xml:space="preserve"
            class="inMandala"
        >
            <bodygraph id="bodygraph" ref="bodyGraph" :chart="chart" dom-ref-id="bodygraph" :interactive="interactive"/>
            </svg>
      </hd-mandala>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Bodygraph from "../bodygraph";
import HdMandala from "../mandala";
import ChartUtil from "../../../mixins/ChartUtil";
import ScreenSizeMixin from "@/mixins/ScreenSizeMixin";
import CompleteChartCanvas from "@/components/charts/rendering/CompleteChartCanvas.vue";

export default{
  mixins: [ChartUtil, ScreenSizeMixin],
  components: {CompleteChartCanvas, HdMandala, Bodygraph},
  props:{
    chart: Object,
    chartLoaded: Boolean,
    interactive:{
      type: Boolean,
      default: true
    },
    hidePlanetTables: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      showLeft: true,
      showRight: true,
    }
  },
  computed: {
    ...mapState({
      showMandala: state => state.ui.showMandala,
      aiEnabled: state => state.ui.singleChart.aiEnabled,
      viewMode: state => state.ui.viewMode,
      viewModes: state => state.ui.viewModes
    }),
  },
}
</script>