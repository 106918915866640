<template>
  <div id="new-personal-chart">
      <mbg-text-input v-model="name" :title="$t('properties.chartName')"
                      :placeholder="$t('general.enterName')" :validation="v$.name"
                      input-classes="new-chart-input" type="text"/>

      <mbg-date-time-input v-model="birthDate" type="text"
                           input-classes="new-chart-input"
                           :format="dateFormat"
                           :title="$t('properties.birthDate')"
                           :placeholder="dateFormat.toLowerCase().split('/').join(' / ')"
                           :validation="v$.birthDate"
                           :disabled="isLockedChart"
      />

      <mbg-date-time-input v-model="birthTime" type="text"
                           input-type="hour"
                           input-classes="new-chart-input"
                           :title="$t('properties.birthTime')" :placeholder="'hh : mm'" :validation="v$.birthTime"
                           :disabled="isLockedChart"/>


      <mbg-country-selector v-model="country" :title="$t('properties.whichCountry')"
                            @countrySelected="countrySelected"
                            :disabled="isLockedChart"/>

      <mbg-city-selector v-if="!existingChart || updateDataInitialized" v-model="city" :title="$t('properties.whichCity')" :country="country"
                         :disabled="isLockedChart"/>

      <div class="mt-4 w-xl-50">
        <b-button v-if="!existingChart" variant="primary" block @click="createChart" :disabled="!fieldsFilled || hasErrors || updating" >
          {{$t(createButtonText18nKey)}}
        </b-button>

        <div v-else>
          <b-button v-if="(!isLockedChart && hasChanged) || nameChanged" class="mb-4" variant="primary" :disabled="!fieldsFilled || hasErrors || updating"
                    @click="updateChart">
            {{$t('pages.newChart.update')}}
          </b-button>
        </div>

      </div>
  </div>
</template>

<script>

import moment from "moment";

import {mapState} from "vuex";
import MbgTextInput from "../elements/MbgTextInput";
import MbgDateTimeInput from "../elements/MbgDateTimeInput";
import MbgCountrySelector from "../elements/MbgCountrySelector";
import MbgCitySelector from "../elements/MbgCitySelector";
import useVuelidate from '@vuelidate/core'
import {required, helpers} from "@vuelidate/validators";
import ChartCreationMixin from "../../mixins/ChartCreationMixin";
import countries from "../../assets/json/countries.json";
import ChartPageMixin from "../../mixins/ChartPageMixin";
import {DATE_FORMATS} from "@/helpers/user_settings";

const checkDateValidity = (data, vm) => {
  if(data.length < 8) return true;
  const date = moment(data, vm.dateFormat);
  return date.isValid() && !date.isBefore('1800-02-01') && !date.isAfter('2099-12-31');
}

const checkTimeValidity = (data, vm) => {
  if(data.length < 4) return true;
  const time = moment(data, vm.timeFormat);
  return time.isValid();
}

const countryObjectFromName = id => countries.find(x => x.id === id)

export default{
  components:{ MbgTextInput, MbgDateTimeInput, MbgCountrySelector, MbgCitySelector},

  props: {
    existingChart: Object,
    cancelRedirect: {
      type: Boolean,
      default: false
    },
    createButtonText18nKey: {
      type: String,
      default: 'pages.newChart.create'
    },
    initialName: String,
    emitDataOnly: {
      type: Boolean,
      default: false
    },
    hidePreviews: {
      type: Boolean,
      default: false
    }
  },
  mixins: [ChartCreationMixin, ChartPageMixin],
  setup () {
    return { v$: useVuelidate() }
  },
  validations: {
    city:{
      required,
      $autoDirty: true
    },
    country:{
      required,
      $autoDirty: true
    },
    birthDate:{
      required,
      checkEquality: helpers.withMessage(() => {
        return 'errors.invalidDate'
      }, checkDateValidity),
      $autoDirty: true
    },
    birthTime:{
      required,
      checkEquality: helpers.withMessage(() => {
        return 'errors.invalidTime'
      }, checkTimeValidity),
      $autoDirty: true
    },
    name:{
      required,
      $autoDirty: true
    }
  },
  data(){
    return{
      editMode: false,
      birthDate: null,
      birthTime: null,
      country: '',
      city: '',
      name: this.$route.query.name || this.initialName || '',
      hasMultiTimezones: false,
      updateDataInitialized: false,
      cities: [],
      cityTextState: '',
      timeFormat: 'HH:mm',
      updating: false,
      creating: false,
    }
  },
  created(){
    if(this.existingChart) this.updateDataBasedOnExistingChart(this.existingChart)
  },
  computed:{
    ...mapState({
      user: state => state.account.user,
      isAdminImpersonation: state => state.ui.isAdminImpersonation,
      dateInputSetting: state => state.account.settings.date_input
    }),

    dateFormat(){
      return DATE_FORMATS[this.dateInputSetting || 0]
    },

    isLockedChart(){
      if(!this.existingChart || this.isAdminImpersonation) return false;
      return Object.hasOwn(this.existingChart.meta, 'is_locked');
    },
    hasErrors(){
      return this.v$ ? this.v$.$errors.length > 0 : false
    },
    hasChanged(){
      let birthData = this.existingChart.meta.birthData;

      return this.city?.name !== birthData.location.city ||
          moment.utc(birthData.time.local).format(this.dateFormat) !== this.birthDate ||
          moment.utc(birthData.time.local).format(this.timeFormat) !== this.birthTime;
    },

    nameChanged(){
      return this.existingChart.meta.name !== this.name
    },

    fieldsFilled(){
      return this.name && this.city && this.country && !!this.birthTime && this.birthDate && this.birthDate.length === 10 && this.birthTime.length === 5;
    },

    creationStatus() {
      const hasName = !!this.name;
      const hasCity = !!this.city;
      const hasCountry = !!this.country;
      const hasBirthTime = !!this.birthTime && !!checkDateValidity(this.birthTime, this) && this.birthTime.length === 5;
      const hasBirthDate = !!this.birthDate && !!checkTimeValidity(this.birthDate, this) && (this.birthDate.length === 10 || this.birthDate.length === 8);

      const totalFilled = [hasName, hasCity, hasCountry, hasBirthTime, hasBirthDate].filter(x => !!x).length;
      return {
        total: totalFilled,
        class: `creation-status-${totalFilled}`
    }
    },
  },

  watch: {
    birthTime(){
      if (this.existingChart) return;
      if (this.fieldsFilled && !this.hidePreviews) this.createChart(false);
    },
    creationStatus(val){
      if (this.existingChart) return;
      if (val.total !== 5) {
        this.$store.commit('charts/SET_CHART', null);
      }
      this.$store.commit('charts/SET_NEW_CHART_ANIMATION_CLASSES', {[val.class]: true});
    },
    updating(val){
      this.$emit('updating', val);
    },
    fieldsFilled(val){
      if (this.existingChart) return;
      if(val){
        if(!this.hasErrors && !this.emitDataOnly && !this.hidePreviews) this.createChart(false);
      }
    },
    city(){
      if(this.existingChart) return;
      if(this.fieldsFilled && !this.hasErrors && !this.emitDataOnly && !this.hidePreviews) this.createChart(false);
      else {
        if(this.hasMultiTimezones) this.$store.state.charts.chart = {}
      }
    },
  },
  mounted(){
    if (this.existingChart) return;
    this.$store.commit('charts/SET_NEW_CHART_ANIMATION_CLASSES', { [this.creationStatus.class]: true });
  },
  beforeDestroy() {
    this.$store.commit('charts/SET_NEW_CHART_ANIMATION_CLASSES', {});
  },
  methods:{
    updateDataBasedOnExistingChart(chart){
      this.name = chart.meta.name;
      let birthData = chart.meta.birthData;

      let birthTime = moment.utc(birthData.time.local);

      if(birthTime.isValid()){
        this.birthDate = birthTime.format(this.dateFormat)
        this.birthTime = birthTime.format(this.timeFormat)
      }

      this.country = countryObjectFromName(birthData.location?.country);
      this.city = {name: birthData?.location?.city};
      if(!this.country){
        this.country = null;
        this.city =null;
      }
      this.updateDataInitialized = true;
    },

    getBirthData(autosave = true){
      const birthDate = moment.utc(this.birthDate, this.dateFormat).format(this.dateFormat); // fixes potential small issues
      const birthTime = moment.utc(this.birthTime, this.timeFormat).format(this.timeFormat); // fixes potential small issues

      return {
        autosave: !!autosave,
        city: this.city.name,
        country: this.country.id,
        time: moment.utc(birthDate + ' ' + birthTime, this.dateFormat + ' ' + this.timeFormat).format(),
        timeInUtc: false,
        timezone: this.city.timezone || this.country.tz
      }
    },
    countrySelected(hasMultiTimezones){
      this.cities = [];
      this.cityTextState = '';
      this.city = null;
      this.hasMultiTimezones = hasMultiTimezones
    },
    createChart(saveChart = true){
      if (this.creating && !saveChart) return;
      this.creating = true;
      if (saveChart) this.updating = true;
      let request = this.getBirthData(saveChart);

      if(this.name) request.name = this.name;

      if (this.emitDataOnly) return this.$emit('chartData', request);

      this.createRaveChart(request).then(chart=>{
        if(!saveChart){
          this.$store.dispatch('charts/setChart', chart);
        } else{
          // we handle this way on chart creations, but we might want the component to just emit the creation id.
          if(!this.cancelRedirect){
            this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.created')});
            this.redirectTo('single-chart', null, {chartId: chart.id});
          }
          else this.$emit('chartCreated', chart);
        }
      }).catch( () =>{
        if(saveChart) this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }).finally(()=>{
        this.updating = false;
        this.creating = false;
      })

    },

    updateChart(){
      let nameUpdated = false;
      if(!this.existingChart) return;

      const isLocked = Object.hasOwn(this.existingChart?.meta, 'is_locked') && !this.isAdminImpersonation;

      if(this.hasErrors) return;
      this.updating = true;

      const data = isLocked ? undefined : this.getBirthData(true);

      this.updateRaveChart(this.existingChart.id, { data: this.hasChanged ? data : undefined, name: this.nameChanged ? this.name : undefined }).then(res=>{
        if(res.chart.id){
          if(!nameUpdated) this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.updated')});
          this.$store.commit('charts/SET_CHART', res.chart);
        }
      }).catch( () =>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }).finally(()=>{
        this.updating = false;
      });
    }
  }
}
</script>

<style lang="scss">
#new-personal-chart{
  padding:1rem;
}
</style>